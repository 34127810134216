<template>
			
		<nav class="sidebar">
      <div class="sidebar-header">
        <a href="" class="sidebar-brand">
          <span>FS</span> COSTOS
        </a>
        <div class="sidebar-toggler not-active">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="sidebar-body">
      
        <ul class="nav">
<!--
          <li class="nav-item nav-category">Comparativas Costo Laboral</li>
       

                    <li class="nav-item" >
                      <router-link :to="{ name: 'Empresas'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Operaciones</span>
                      </router-link>
                    </li>
                    


                     <li class="nav-item">
                      <router-link :to="{ name: 'Sectores'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Sectores Planta</span>
                      </router-link>
                    </li>

                       <li class="nav-item">
                      <router-link :to="{ name: 'Salida'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Cantidad por Empresas</span>
                      </router-link>
                    </li>

                     <li class="nav-item">
                      <router-link :to="{ name: 'Plato'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Cantidad por Plato</span>
                      </router-link>
                    </li>

                     <li class="nav-item">
                      <router-link :to="{ name: 'Cocina'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Cantidad por Cocina</span>
                      </router-link>
                    </li>



                    <li class="nav-item">
                      <router-link :to="{ name: 'CostosPersonal'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">CL America</span>
                      </router-link>
                    </li>

                    <li class="nav-item">
                      <router-link :to="{ name: 'CostosPersonalCat'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">CL Catering</span>
                      </router-link>
                    </li>

                     <li class="nav-item">
                      <router-link :to="{ name: 'CostosPersonalGroup'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">CA Agrupados</span>
                      </router-link>
                    </li>

                    <li class="nav-item nav-category">Proyecciòn Basico</li>

                     <li class="nav-item">
                      <router-link :to="{ name: 'CostosPersonalP'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Autorizaciones America</span>
                      </router-link>
                    </li>

                    <li class="nav-item">
                      <router-link :to="{ name: 'CostosPersonalCatP'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Autorizaciones Catering</span>
                      </router-link>
                    </li>

                     <li class="nav-item">
                      <router-link :to="{ name: 'CostosPersonalPGroup'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Autori. Agrupados</span>
                      </router-link>
                    </li>
-->
                    <li class="nav-item nav-category">PROYECCIONES AUTORIZADAS</li>

                 <!--   <li class="nav-item">
                      <router-link :to="{ name: 'CostosGuardados'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Autori. Guardados Basicos</span>
                      </router-link>
                    </li>

                   <li class="nav-item">
                      <router-link :to="{ name: 'BloquesGuardados'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Bloques Guardados</span>
                      </router-link>
                    </li>-->

                     <li class="nav-item" v-if="isLector('CrearProyeccion')==true">
                      <router-link :to="{ name: 'ProGuardados'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Proyecciones Nomina</span>
                      </router-link>
                    </li>

                     <li class="nav-item" v-if="isLector('VerProyeccion')==true">
                      <router-link :to="{ name: 'ViewConvenio'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Nomina en convenio</span>
                      </router-link>
                    </li>

                    <li class="nav-item" v-if="isLector('VerProyeccion')==true">
                      <router-link :to="{ name: 'ViewGuardados'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Ver Mod. Salariales</span>
                      </router-link>
                    </li>

                    <li class="nav-item" v-if="isLector('DescargarProyeccion')==true">
                      <router-link :to="{ name: 'DownGuardados'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Descargar Mod. Salariales</span>
                      </router-link>
                    </li>

                    <li class="nav-item" v-if="isLector('RequiSalarial')==true">
                      <router-link :to="{ name: 'RequiSalarial'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Ajuste mes referencia</span>
                      </router-link>
                    </li>

                      <li class="nav-item" v-if="isLector('CrearProyeccion')==true">
                      <router-link :to="{ name: 'EvolucionSalarial'}" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Evolución Salarial</span>
                      </router-link>
                    </li>
                 
              
            <!-- CIERRA COSTOS DIRECTOS  -->


         
        
        </ul>
      </div>
    </nav>
 
</template>

<script>


const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));


export default {
  name: 'Menu',
    methods:{
     isLector(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Lector;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            // console.log(modulo+' : '+false)
            return false;
          }
      },
       mounted() {
       //this.isLector('Empresas');
    },
  }
};






</script>