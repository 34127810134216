<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}</p>



            
<div class="row">

    <div class="col-md-4">
                                <select id="liquidacion-a">


                                </select>

</div>

    


 <div class="col-md-4">
     
      <button class="btn btn-primary mr-2 mb-2 mb-md-0 text-white" v-on:click="comparar()">PROYECTAR</button>
 </div>

</div>

<br><br>
<table class="table" width="100%">
 
      <tr>
          <td colspan="2" style="background-color:#d6daad;" class="fecha1">Diciembre 2021</td>
          <td colspan="2" style="background-color:#e2b79d;" class="">Proyecciòn</td>
          <td  style="background-color:#ea9460;">Observacion</td>
      </tr>  

      <tr>
          <td style="background-color:#d6daad;">Personal</td>
          <td style="background-color:#d6daad;">Costo total</td>

          <td style="background-color:#e2b79d;">Personal</td>
          <td style="background-color:#e2b79d;">Costo total</td>

          <td style="background-color:#ea9460;">Diferencia</td>
      </tr>  

        <tr>
          <td style="background-color:#d6daad;" id="personal_a">0</td>
          <td style="background-color:#d6daad;" id="total_a">$0</td>

          <td style="background-color:#e2b79d;"><span id="personal_b">0</span>  <span id="porc_per_a"></span></td>
          <td style="background-color:#e2b79d;"><span id="total_b">$0</span> <span id="porc_cos_a"></span></td>

          <td style="background-color:#ea9460;"><span id="diferencia_t">$0</span> <span id="porc_dif"></span></td>
      </tr> 

</table>

                        </div>  
                      </div>
                    </div>
                  </div>    



                  <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                

<table width="100%">
    <tr>
        <td width="200px">Filtrar por Sector/Operacion</td>
        <td><select id="filtro_sector"><option value="">Todos</option></select></td>
    </tr>

    <tr>
        <td width="200px">Filtrar por Convenio/Fuera de convenio</td>
        <td><select id="filtro_convenio"><option value="">Todos</option></select></td>
    </tr>

    <tr>
        <td width="200px">Resultado del filtro</td>
        <td id="filtro"> <span id="f_oper"></span> <span id="f_conv"></span> <span id="f_total"></span> </td>
    </tr>
</table>



<br>
<table width="100%">
    <td align="right">
         
         <button style="width:275px;" class="btn btn-warning mr-2 mb-2 mb-md-0 text-white" v-on:click="guardar()">GUARDAR PROYECCÒN BASICA</button>
    </td>
</table>
<br>


                <div class="table-responsive">
                  
                  

                  <table id="empresas" class="table" width="100%">
                    <thead>
                       <tr >
                        <td colspan="5" align="center" style="background-color:#d6daad;">Datos generales de nomina</td>
                        <td colspan="1" align="center" style="background-color:#e2b79d;">Costo Liquidacion <span class="fecha1">Diciembre 2021</span></td>
                        <td colspan="1" align="center" style="background-color:#ff9a73;">Costo Liquidacion Proyectada</td>
                        <td  colspan="2" align="center" style="background-color:#b7d0b4;">Aumento sobre el basico</td>
                        </tr>
                      
                      <tr>
                       
                       
                        <th style="background-color:#d6daad;">Legajo</th>
                        <th style="background-color:#d6daad;">Nombre</th>
                        
                        
                        <!--<th style="background-color:#d6daad;">Ingreso</th>-->
                        <th style="background-color:#d6daad;">Categoria</th>
                        <th style="background-color:#d6daad;">Sector/Operacion</th>
                        <th style="background-color:#d6daad;">Convenio</th>

                        <th style="background-color:#e2b79d;">Basico</th>
                        

                        
                        <th style="background-color:#ff9a73;">Basico</th>
                       

                        <th style="background-color:#b7d0b4;">Diferencia</th>
                        <th style="background-color:#b7d0b4;">%</th>
                        
                       
                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr  v-for="personal in personal"  v-bind:key="personal.legajo" >
                        
                        
                        <td class="details-control">{{personal.legajo}}</td>
                        <td :id="'nombre-'+personal.legajo">{{personal.apellido}} {{personal.nombre}}</td>
                        
                        <td :id="'categoria-'+personal.legajo">{{personal.categoria}}</td>
                        <td :id="'ubicacion-'+personal.legajo">{{personal.ubicacion}}</td>
                        <td :id="'convenio-'+personal.legajo">{{personal.convenio}}</td>
                        <td>$ <span :id="'basico_a-'+personal.legajo">{{personal.basico_a}}</span></td>
                        

                        
                         <td><span :id="'proyeccion_a-'+personal.legajo">0</span></td>
                        

                        <td><span :id="'diferencia-'+personal.legajo" class="sumar">0</span></td>
                        
                        <td>%
                       
                        
                        <span> <input size="2" type="text" :id="'basico_b-'+personal.legajo" v-on:keyup="calcular(personal.legajo)" value=""></span>

                        
                        </td>
                       
                        
                      
                      </tr>
                    


                    </tbody>
                  </table>

<div class="alert alert-warning" role="alert" id="alerta">
  SELECCIONA LIQUIDACIÒN A PROYECTAR
</div>


                     <div class="col-md-12 grid-margin stretch-card" id="loading">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-title w-25"><div class="load-box"></div></div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                </div>
              </div>
            </div>
          </div>
        </div>


            </div>
     

</template>

<script>

import axios from 'axios';
import 'select2';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

import Swal from "sweetalert2";
import firebase from "firebase/app";
import "firebase/database";
var selector = [];
 const addZero=(i)=>{
                    if (i < 10) {
                        i = "0" + i;
                    }
                    return i;
                }


export default {
    name: 'Personal',


    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Salarios basicos de Personal America y Catering',
            descripcion: 'En este modulo usted podra visualizar y comparar el costo del personal de la empresa',
            personal: [],
        }
    },

    async mounted() {


for (var i = 0; i < sessionStorage.length; i++) {
              var key = sessionStorage.key(i);
              if (key.indexOf('totalx') !== -1||key.indexOf('proyeccion') !== -1||key.indexOf('porcentaje') !== -1) {
                sessionStorage.removeItem(key);
                
              }
            }


    const axios1 = axios.get('https://aplicativos.firebaseio.com/liquidacion_recibos/AME.json');
    const axios2 = axios.get('https://aplicativos.firebaseio.com/liquidacion_recibos/CAT.json');
       

    await axios.all([axios1, axios2]).then(axios.spread((america, catering) => {

var liquidaciones = [];
$.each(america.data,function(clave,valor){
    liquidaciones.push({"anio":valor.anio,"liquidacion_america":valor.liquidacion,"mes":valor.mes,"option":valor.option})
    
 });

$.each(catering.data,function(clave,valor){
    liquidaciones.push({"anio":valor.anio,"liquidacion_catering":valor.liquidacion,"mes":valor.mes,"option":valor.option})
    
 });

const mergedData = Object.values(liquidaciones.reduce((acc, curr) => {
  const caption = curr.option.split(" ")[0];
  if (!acc[caption]) {
    acc[caption] = [];
  }
  acc[caption].push(curr);
  return acc;
}, {})).map(group => group.reduce((acc, curr) => Object.assign(acc, curr), {}));



    var count = 0;
    var limite = Object.keys(mergedData).length;
     $.each(mergedData,function(clave,valor){
        count++

        var mes = valor.mes;
        if (mes<10) {
            mes="0"+mes
        }
        console.log(count+" == "+limite)
        if (count==limite) {

            $("#liquidacion-a").prepend('<option selected="true" value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            $("#liquidacion-b").prepend('<option selected="true" data-fecha="'+valor.anio+'-'+mes+'-28" value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            selector.push({ [valor.mes+'-'+valor.anio+'-'+valor.liquidacion_america+'-'+valor.liquidacion_catering]:valor.option })

        }else{

            $("#liquidacion-a").prepend('<option value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            $("#liquidacion-b").prepend('<option data-fecha="'+valor.anio+'-'+mes+'-28" value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            selector.push({ [valor.mes+'-'+valor.anio+'-'+valor.liquidacion_america+'-'+valor.liquidacion_catering]:valor.option })

        }
        
     

     });




})).then(() => {


            
$("#loading").hide();
$(".table").hide();
               

        });


    },
     methods: {


        guardar() {

               
                    (async function() {


        const userData = JSON.parse(sessionStorage.getItem('userData'));
        var h = new Date();
        var hora = addZero(h.getHours());
        var minutos = ("0" + h.getMinutes()).substr(-2);
        var segundos = ("0" + h.getSeconds()).substr(-2);
        var horaActual = hora + ":" + minutos + ":" + segundos;

        var hoy = new Date();

        var m = hoy.getMonth() + 1;
        var mes = m < 10 ? "0" + m : m;
        var dia = hoy.getDate();
        dia = dia < 10 ? "0" + dia : dia;
        var fechaActualok = dia + "-" + mes + "-" + hoy.getFullYear();
        var fechaActualok_b = hoy.getFullYear() + "-" + mes + "-" + dia;




            

            var data = [];
            var datos = [];
            for (var i = 0; i < sessionStorage.length; i++) {
              var key = sessionStorage.key(i);
              if (key.indexOf('totalx') !== -1||key.indexOf('proyeccion') !== -1||key.indexOf('porcentaje') !== -1) {
                var value = sessionStorage.getItem(key);
                console.log('"'+key + '": ' + value);
                data.push(key);
                datos.push({[key]: value});
              }
            }

            if (data.length>0) {


                await Swal.fire({
                      title: 'Escribe un nombre para guardar tus modificaciones',
                      input: 'text',
                      inputAttributes: {
                        autocapitalize: 'off'
                      },
                      showCancelButton: true,
                      cancelButtonText: 'Cancelar',
                      confirmButtonText: 'Guardar',
                      showLoaderOnConfirm: true,
                      preConfirm: (login) => {
                       console.log(login)
                       console.log("tester")
                       console.log($("#porc_dif").html())
                            firebase.database().ref('costos_guardados/').push({
                              nombre:login,
                              fecha:fechaActualok_b,
                              timestamp:fechaActualok_b+" "+horaActual,
                              creador_dni:userData.leg_numdoc,
                              creador_name:userData.leg_nombre+" "+userData.leg_apellido,
                              liquidacion:localStorage.getItem("liquidacion"),
                              datos:datos,
                              proyeccion:$("#total_b").html(),
                              proyeccion_p:$("#porc_cos_a").html(),
                              diferencia_t:$("#diferencia_t").html(),
                              porc_dif:$("#porc_dif").html()


                            })
                            .then(()=>{
                              
                                 Swal.fire(
                                      "Guardado exitosamente",
                                      "Los datos fueron guardados cotrrectamente, podras visualizarlos en la secciòn Autorizados Guardados",
                                      "success"
                                    );

                            })
                            .catch(e => {
                              console.log(e);
                            });


                      },
                      allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                      if (result.isConfirmed) {
                        console.log(result);
                      }
                    })

            }else{

                Swal.fire(
                          "ATENCIÒN",
                          "No tienes datos modificados para guardar, asegurate de editar o agregar % porcentajes como minimo en una persona para poder guardar",
                          "warning"
                );

            }


})();

           
         },

async bloque(){

var liquidacion = "";
var partes="";
var porcentaje="";
var titulo="";
var mes_l = "";
var anio_l = "";


 const userData = JSON.parse(sessionStorage.getItem('userData'));
        var h = new Date();
        var hora = addZero(h.getHours());
        var minutos = ("0" + h.getMinutes()).substr(-2);
        var segundos = ("0" + h.getSeconds()).substr(-2);
        var horaActual = hora + ":" + minutos + ":" + segundos;

        var hoy = new Date();

        var m = hoy.getMonth() + 1;
        var mes = m < 10 ? "0" + m : m;
        var dia = hoy.getDate();
        dia = dia < 10 ? "0" + dia : dia;
        var fechaActualok = dia + "-" + mes + "-" + hoy.getFullYear();
        var fechaActualok_b = hoy.getFullYear() + "-" + mes + "-" + dia;


const result = selector.reduce((acc, obj) => {
  return {...acc, ...obj};
}, {});

console.log(result)

  const { value: fruit } = await Swal.fire({
  title: 'Selecciona una liquidacion de inicio',
  input: 'select',
  inputOptions: result,
  inputPlaceholder: 'Selecciona una liquidaciòn',
  showCancelButton: true,
  cancelButtonText: 'Cancelar',
  confirmButtonText: 'Siguiente',
   inputValidator: (value) => {
   
            console.log(value)
            liquidacion = value.split("-")[2]+"-"+value.split("-")[3];
            mes_l = value.split("-")[0];
            anio_l = value.split("-")[1];


            Swal.fire({
              title: 'Escribe el numero de partes de la proyecciòn',
             input: 'number',
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              confirmButtonText: 'Siguiente',
              inputValidator: (value) => {
                console.log(value)
                partes = value;

                        Swal.fire({
                          title: 'Escribe el porcentaje final esperado %',
                          input: 'number',
                          showCancelButton: true,
                          cancelButtonText: 'Cancelar',
                          confirmButtonText: 'Siguiente',
                          inputValidator: (value) => {
                            console.log(value)
                            porcentaje = value;
                            Swal.fire({
                                  title: 'Escribe un titulo al bloque',
                                  input: 'text',
                                  showCancelButton: true,
                                  cancelButtonText: 'Cancelar',
                                  confirmButtonText: 'Guardar',
                                  inputValidator: (value) => {
                                    console.log(value)
                                        titulo=value;
                                        firebase.database().ref('bloques_guardados/').push({
                                          nombre:titulo,
                                          fecha:fechaActualok_b,
                                          timestamp:fechaActualok_b+" "+horaActual,
                                          creador_dni:userData.leg_numdoc,
                                          creador_name:userData.leg_nombre+" "+userData.leg_apellido,
                                          liquidacion:liquidacion,
                                          partes:partes,
                                          porcentaje:porcentaje,
                                          mes_l:mes_l,
                                          anio_l:anio_l
                                        })
                                        .then(()=>{
                                          
                                             Swal.fire(
                                                  "Guardado exitosamente",
                                                  "Los datos fueron guardados cotrrectamente, podras visualizarlos en la secciòn Autorizados Guardados",
                                                  "success"
                                                );

                                        })
                                        .catch(e => {
                                          console.log(e);
                                        });


                                  }
                                })


                          }
                        })



              }
            })




  }
})



},



        removeDuplicates(originalArray, prop) {
             var newArray = [];
             var lookupObject  = {};

             for(var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
             }

             for(i in lookupObject) {
                 newArray.push(lookupObject[i]);
             }
              return newArray;
         },

         calcular(legajo){

             const moneda = (numero) => {
              const formatoMoneda = new Intl.NumberFormat('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 0, // Establecer el número mínimo de dígitos decimales a cero
                maximumFractionDigits: 0, // Establecer el número máximo de dígitos decimales a cero
                currencyDisplay: 'narrowSymbol' // Opción para eliminar el símbolo de moneda
              });

              const numeroFormateado = formatoMoneda.format(numero);

              return numeroFormateado;
            }


                  var per = parseInt($("#basico_b-"+legajo).val().toString().replace(/\./g, ''));
                  var basico = parseFloat($("#basico_a-"+legajo).html().toString().replace(/\./g, ''));
                  var cuenta = (basico*per)/100;
                  var total = parseFloat(basico+cuenta);





                    if (isNaN(total)||total==0) {
                      total = 0;
                      $("#proyeccion_a-"+legajo).html(0)
                    } else {
                      $("#proyeccion_a-"+legajo).html(moneda(total).replace("$",""))
                    }


                    //DIFERENCIA

                    var direfencia = total-basico;
                    console.log(direfencia)

                    if (isNaN(direfencia)||direfencia<0) {
                      
                      $("#diferencia-"+legajo).html(0)
                      sessionStorage.setItem("total-"+legajo,0)
                      sessionStorage.setItem("totalx-"+legajo,0)
                      sessionStorage.setItem("porcentaje-"+legajo,0)
                      sessionStorage.setItem("proyeccion-"+legajo,0)
                    } else {
                      $("#diferencia-"+legajo).html(moneda(direfencia))
                      sessionStorage.setItem("total-"+legajo,direfencia.toFixed(3).replace(".",","))
                      sessionStorage.setItem("totalx-"+legajo,direfencia.toFixed(3).replace(".",","))
                      sessionStorage.setItem("porcentaje-"+legajo,per)
                      sessionStorage.setItem("proyeccion-"+legajo,total)
                    }



   var table = $('#empresas').DataTable();                     
  // Obtener los datos de la columna "País"
  var data = table.column(0).data();

  // Crear un array de valores únicos de la columna "País"
  var uniqueData = data.unique();

  
  var sumar = 0;
  // Agregar las opciones del filtro select con los valores únicos de la columna "País"
  $.each(uniqueData, function(i, val) {

    if (sessionStorage.getItem("total-"+val) !== null) {
        sumar+=parseFloat(sessionStorage.getItem("total-"+val));
    }
    
        
    
  });

  console.log("SUMA == "+sumar)


const numeroMoneda = localStorage.getItem("costo");
const numeroEntero = parseFloat(numeroMoneda.replace(/\$|\.+/g, ''));
var costo=moneda(numeroEntero+sumar); // output: 112337086
var costo_entero=parseFloat(numeroEntero+sumar);
$("#total_b").html(costo)                   

 

 var porcentajeAumento = ((costo_entero - numeroEntero) / numeroEntero) * 100;
 var porcentaje = parseFloat(porcentajeAumento.toFixed(2));
 console.log("PORCENTAJE :: "+porcentaje)

 var resta = costo_entero-numeroEntero;

if (porcentaje>0.00) {
      $("#porc_cos_a").html('<span class="arraw igual badge badge-danger"> %'+porcentaje+' ↑</span>')   
}else{
     $("#porc_cos_a").html('<span class="arraw igual badge badge-light"> %'+porcentaje+' ↔</span>')   
}

if (costo_entero!=numeroEntero) {
      $("#diferencia_t").html(moneda(resta))
      $("#porc_dif").html('<span class="arraw igual badge badge-danger">↑</span>')   
}else{
     $("#diferencia_t").html(moneda(resta))
     $("#porc_dif").html('<span class="arraw igual badge badge-light">↔</span>')     
}


                


                  
        },



        async comparar(){

            $("#loading").show();

            

            const moneda = (numero) => {

                var nu = numero.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
                return nu = nu.split('').reverse().join('').replace(/^[.]/,'');
            
            }


          
$(".table").show();
$("#alerta").hide(); 

        $('#empresas').DataTable().destroy();

         var liq_1_a = $("#liquidacion-a option:selected").val().split("-")[0];
         var liq_1_b = $("#liquidacion-a option:selected").val().split("-")[1];
         
        

         $(".fecha1").html($("#liquidacion-a option:selected").text())
         $(".fecha2").html( $("#liquidacion-b option:selected").text())

         

        const axiosrequest1 = axios.get('https://auth.foodservice.com.ar/?type=personal&access_token=1234567');
        const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/categorias.json?print=pretty');
        
        const axiosrequest3 = axios.get('https://auth.foodservice.com.ar/?type=costos_ame&liquidacion='+liq_1_a+'&access_token=1234567');
        const axiosrequest4 = axios.get('https://auth.foodservice.com.ar/?type=costos_cat&liquidacion='+liq_1_b+'&access_token=1234567');
        localStorage.setItem("liquidacion",liq_1_a+"-"+liq_1_b)
       

       await axios.all([axiosrequest1, axiosrequest2, axiosrequest3,axiosrequest4]).then(axios.spread((personal, categorias, costo_a,costo_b) => {

            var lista = [];
           
            const costox_a = Object.assign({}, costo_a.data, costo_b.data);
            console.log(costox_a); 

            const costox_b = Object.assign({}, costo_a.data, costo_b.data);
            console.log(costox_b); 
            
            $("#personal_a").html(Object.keys(costox_a).length);
            $("#personal_b").html(Object.keys(costox_b).length);

            var c_a = 0;
            $.each(costox_a, function(clave, value) {
                console.log(value.datos.basico)
                if (value.datos.basico==null) {
                    c_a += 0;
                }else{
                    c_a += parseInt(value.datos.basico);
                }
                


            })

             var num_a = c_a.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
                 num_a = num_a.split('').reverse().join('').replace(/^[.]/,'');
            $("#total_a").html("$"+num_a);


             var c_b = 0;
            $.each(costox_a, function(clave, value) {
                c_b += parseInt(value.costo_laboral.monto.toFixed(2));


            })

         

            $("#total_b").html("$"+num_a);
            localStorage.setItem("costo",num_a)

            var resultado = c_a-c_a;

             var result = resultado.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
                 result = result.split('').reverse().join('').replace(/^[.]/,'');

            $("#diferencia_t").html("$"+result);




            var pa = parseInt($("#personal_a").text());
            var pb = parseInt($("#personal_a").text());
            var porc_a = (pb-pa)*100/pa;

            var porcx_a = "";

            if (porc_a>0) {
                porcx_a = '<span class="arraw up badge badge-danger"> %'+Math.round(porc_a)+' ↑</span>';
            }
            if (porc_a<0) {
                porcx_a = '<span class="arraw down badge badge-success"> %'+Math.round(porc_a)+' ↓</span>';
            }
            if (porc_a==0) {
                porcx_a = '<span class="arraw igual badge badge-light"> %'+Math.round(porc_a)+' ↔</span>';
            }

            $("#porc_per_a").html(porcx_a);



            var pca = parseInt($("#total_a").text().replace("$",""));
            var pcb = parseInt($("#total_a").text().replace("$",""));
            var porc_b = (pcb-pca)*100/pca;

            var porcx_b = "";

            if (porc_b>0) {
                porcx_b = '<span class="arraw up badge badge-danger"> %'+Math.round(porc_b)+' ↑</span>';
            }
            if (porc_b<0) {
                porcx_b = '<span class="arraw down badge badge-success"> %'+Math.round(porc_b)+' ↓</span>';
            }
            if (porc_b==0) {
                porcx_b = '<span class="arraw igual badge badge-light"> %'+Math.round(porc_b)+' ↔</span>';
            }

            $("#porc_cos_a").html(porcx_b);


            var dif_a = parseInt($("#total_a").text().replace("$",""));
           

            var difx_b = "";

            if (dif_a>0) {
                difx_b = '<span class="arraw up badge badge-danger"> ↑ </span>';
            }
            if (dif_a<0) {
                difx_b = '<span class="arraw down badge badge-success"> ↓ </span>';
            }
            if (dif_a==0) {
                difx_b = '<span class="arraw igual badge badge-light"> ↔ </span>';
            }

            $("#porc_dif").html('<span class="arraw igual badge badge-light"> ↔ </span>');


            const convencionado = (id) => {
                var convenio = "";
                $.each(personal.data, function(clave, value) {

                    if (value.leg_numero == id) {
                        convenio = value.leg_convencionado;
                    }
                })
                return convenio;
            }


            const result_categoria = (id) => {
                var categoria = "";
                $.each(categorias.data, function(clave, value) {

                    if (value.codigo == id) {
                        categoria = value.nombre;
                    }
                })
                return categoria;
            }

           


            

            const result_remunerativo_a = (id) => {
                var remunerativo = "";
                $.each(costox_a, function(clave, value) {

                    if (clave == id) {
                        remunerativo = parseFloat(value.remunerativo.monto).toFixed(0);
                    }
                })
                return remunerativo;
            }

            const result_noremunerativo_a = (id) => {
                var noremunerativo = "";
                $.each(costox_a, function(clave, value) {

                    if (clave == id) {

                        if (value.no_remunerativo!=null) {

                        noremunerativo = parseFloat(value.no_remunerativo.monto).toFixed(0);

                        }
                    }
                })
                return noremunerativo;
            }

            const result_total_a = (id) => {
                var costo_laboral = "";
                $.each(costox_a, function(clave, value) {

                    if (clave == id) {

                        if (value.costo_laboral!=null) {

                        costo_laboral = parseFloat(value.costo_laboral.monto).toFixed(0);

                        }
                    }
                })
                return costo_laboral;
            }

            

            const result_remunerativo_b = (id) => {
                var remunerativo = "";
                $.each(costox_b, function(clave, value) {

                    if (clave == id) {
                        remunerativo = parseFloat(value.remunerativo.monto).toFixed(0);
                    }
                })
                return remunerativo;
            }

            const result_noremunerativo_b = (id) => {
                var noremunerativo = "";
                $.each(costox_b, function(clave, value) {

                    if (clave == id) {

                        if (value.no_remunerativo!=null) {

                        noremunerativo = parseFloat(value.no_remunerativo.monto).toFixed(0);

                        }
                    }
                })
                return noremunerativo;
            }

            const result_total_b = (id) => {
                var costo_laboral = "";
                $.each(costox_b, function(clave, value) {

                    if (clave == id) {

                        if (value.costo_laboral!=null) {

                        costo_laboral = value.costo_laboral.monto.toFixed(0);

                        }
                    }
                })
                return costo_laboral;
            }



            const basico_a = (id) => {
                var basico = "";
                $.each(costox_a, function(clave, value) {

                    if (clave == id) {
                        if (value['datos'].basico==null) {
                            basico=0;
                        }else{
                            basico = parseFloat(value['datos'].basico).toFixed(0);
                        }
                        
                    }
                })
                return basico;
            }

            const basico_b = (id) => {
                var basico = "";
                $.each(costox_b, function(clave, value) {

                   if (clave == id) {
                        if (value['datos'].basico==null) {
                            basico=0;
                        }else{
                            basico = parseFloat(value['datos'].basico).toFixed(0);
                        }
                        
                    }
                })
                return basico;
            }

            

             // abre liquidacion 1
            $.each(costox_a, function(clave, valor) {


                    
                    var p_total = (basico_b(clave)-basico_a(clave))*100/basico_a(clave);

                    if (p_total==Infinity) {
                         p_total = (basico_a(clave)-basico_b(clave))*100/basico_b(clave);
                    }

               
                    var convenio = "FUERA DE CONVENIO";
                    if (convencionado(clave)==1) {
                        convenio = "CCT";
                    }

                    sessionStorage.removeItem("total-"+clave)

                    lista.push({
                      
                        "legajo": clave,
                        "nombre": valor.datos.nombre.split(', ')[1],
                        "apellido": valor.datos.nombre.split(', ')[0],
                        "ingreso": '',
                        "categoria": result_categoria(valor.datos.cat_tipo),
                        "remunerativo_a": moneda(result_remunerativo_a(clave)),
                        "noremunerativo_a": moneda(result_noremunerativo_a(clave)),
                        "costo_a": moneda(result_total_a(clave)),
                        "remunerativo_b": moneda(result_remunerativo_b(clave)),
                        "noremunerativo_b": moneda(result_noremunerativo_b(clave)),
                        "costo_b": moneda(result_total_b(clave)),
                        "porcentaje": Math.round(p_total),
                        "diferencia":moneda((basico_b(clave)-basico_a(clave)).toFixed(0)),
                        "basico_a":moneda(basico_a(clave)),
                        "basico_b":moneda(basico_b(clave)),
                        "ubicacion":localStorage.getItem("operacion-"+localStorage.getItem("ubicacion-"+clave)),
                        "convenio":convenio

                    })

                


            });

            // cierra liquidacion 1

           
     
          
var unicos = this.removeDuplicates(lista, "legajo");


            this.personal = unicos;
            $("#loading").hide();

        })).then(() => {


Array.prototype.unique = function() {
  var a = [];
  for (var i = 0, l = this.length; i < l; i++)
    if (a.indexOf(this[i]) === -1)
      a.push(this[i]);
  return a;
};

           var table = $('#empresas').DataTable({
                "scrollX": true,
                 "autoWidth": true,

                buttons: [
                    'copy',
                    'excel',
                    'csv',
                    'pdf'
                ]
            }).columns.adjust();


   var convenio = "-";
  // Obtener los datos de la columna "sectores"
  var data = table.column(3).data();

  // Crear un array de valores únicos de la columna "sectores"
  var uniqueData = data.unique();

  // Agregar un filtro select en el encabezado de la columna "sectores"
  var select = $('#filtro_sector').on('change', function() {
  var val = $(this).val();
 

          table.column(3).search(val).draw();
          
          var total = 0;
          var sector = "-";
          table.rows({search: 'applied'}).data().each(function(row) {

            sector = row[3];
            if (val=="") {
                localStorage.setItem("filtro_sector","");
            }else{
                localStorage.setItem("filtro_sector",sector);
            }
            
            // Expresión regular para encontrar el número
            var regex = /(\d+\.\d+)/;
            var numero = 0;
            console.log("valores")
            console.log(row[5].match(regex))
            if (row[5].match(regex)!=null) {
            // Extraer el número de la cadena de texto
            numero = row[5].match(regex)[1];
            total += parseInt(numero.replace('.', ''));

        }

        

  });

         


if (localStorage.getItem("filtro_sector")==""&&localStorage.getItem("filtro_convenio")=="") {
    total = localStorage.getItem("costo").replace(".","").replace(".","");
}
 

   $("#filtro #f_oper").html('<span class="arraw igual badge badge-light">'+sector+'</span>')

   $("#filtro #f_total").html('<b>Total:</b> <span class="arraw igual badge badge-success">$'+moneda(total)+'</span>')

});

  // Agregar las opciones del filtro select con los valores únicos de la columna "sectores"
  $.each(uniqueData, function(i, val) {
        if (val!="") {
            select.append('<option value="' + val + '">' + val + '</option>');
        }
  });

         
$('#filtro_sector').select2();


  
/* filtro de convenio */
    var oper ="";
// Obtener los datos de la columna "País"
  var data_c = table.column(4).data();

  // Crear un array de valores únicos de la columna "País"
  var uniqueData_c = data_c.unique();

  var select_c = $('#filtro_convenio').on('change', function() {
  var val = $(this).val();
 
  table.column(4).search(val).draw();
  

  var total_c = 0;
  
  table.rows({search: 'applied'}).data().each(function(row) {
    convenio = row[4];
   
            if (val=="") {
                localStorage.setItem("filtro_convenio","");
            }else{
                localStorage.setItem("filtro_convenio",convenio);
            }

            

    
    // Expresión regular para encontrar el número
    var regex = /(\d+\.\d+)/;
    var numero = 0;
     if (row[5].match(regex)!=null) {
            // Extraer el número de la cadena de texto
            numero = row[5].match(regex)[1];
            total_c += parseInt(numero.replace('.', ''));

        }
  });
  console.log('Total:', total_c);



var conv = localStorage.getItem("filtro_convenio");
console.log("valores_2")
console.log(localStorage.getItem("filtro_sector"))



if (localStorage.getItem("filtro_sector")==""&&localStorage.getItem("filtro_convenio")=="") {
    total_c = localStorage.getItem("costo").replace(".","").replace(".","");
}

  $("#filtro #f_conv").html('<span class="arraw igual badge badge-light">'+conv+'</span>')

   $("#filtro #f_total").html('<b>Total:</b> <span class="arraw igual badge badge-success">$'+moneda(total_c)+'</span>')

});

  // Agregar las opciones del filtro select con los valores únicos de la columna "País"
  $.each(uniqueData_c, function(i, val_c) {
    if (val_c!="") {
            select_c.append('<option value="' + val_c + '">' + val_c + '</option>');
        }
  });

         
$('#filtro_convenio').select2();







               

        });



        }

    },



};
</script>

<style scoped>
.load-box {
    background: red;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
}

.up{
    color:green;
}
.down{
    color:red;
}

.colapse{
    position: absolute;
}
</style>
