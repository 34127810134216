<template>
   <div class="page-content">
      <nav class="page-breadcrumb" id="breadcrumb">
         <ol class="breadcrumb">
            <li class="breadcrumb-item">{{breadcrumbA}}</li>
            <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
         </ol>
      </nav>
      <div class="row">
         <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
               <div class="card-body">
                  <h6 class="card-title">{{breadcrumbB}}</h6>
                  <p class="card-description">
                     {{descripcion}}
                  </p>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
               <div class="card-body">
                  <div class="table-responsive">
                     <table id="empresas" class="table">
                        <thead>
                           <tr>
                              <th>Cod</th>
                              <th>Costos</th>
                              <th>Nombre</th>
                              <th>Tipo</th>
                              <th>Per</th>
                              <th>Costo directo</th>
                              <th>Costo indirecto</th>
                              <th>Total</th>
                              <th>Acción</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr  v-for="operaciones in operaciones"  v-bind:key="operaciones.id" >
                              <td>{{operaciones.centroapropiacion_id}}</td>
                              <td>{{operaciones.codigo_costos}}</td>
                              <td>{{operaciones.name}}</td>
                              <td>{{operaciones.tipo_empresa}}</td>
                              <td>{{operaciones.personal}}</td>
                              <td>$ {{operaciones.costo}}</td>
                              <td>$ {{operaciones.indirecto}}</td>
                              <td>$ {{operaciones.total}}</td>
                              <td><button @click="detalle(operaciones.id)"  type="button" class="btn btn-primary btn-xs" data-toggle="modal" data-target=".nuevo">Ver detalle</button></td>
                           </tr>
                        </tbody>
                     </table>
                     <div class="col-md-12 grid-margin stretch-card" id="loading">
                        <div class="card">
                           <div class="card-body">
                              <div class="card-title w-25">
                                 <div class="load-box"></div>
                              </div>
                              <hr>
                              <div class="row mb-2">
                                 <div class="col-2">
                                    <div class="load-box"></div>
                                 </div>
                                 <div class="col-2">
                                    <div class="load-box"></div>
                                 </div>
                                 <div class="col-4">
                                    <div class="load-box"></div>
                                 </div>
                                 <div class="col-4">
                                    <div class="load-box"></div>
                                 </div>
                              </div>
                              <hr>
                              <div class="row mb-2">
                                 <div class="col-2">
                                    <div class="load-box"></div>
                                 </div>
                                 <div class="col-2">
                                    <div class="load-box"></div>
                                 </div>
                                 <div class="col-4">
                                    <div class="load-box"></div>
                                 </div>
                                 <div class="col-4">
                                    <div class="load-box"></div>
                                 </div>
                              </div>
                              <hr>
                              <div class="row mb-2">
                                 <div class="col-2">
                                    <div class="load-box"></div>
                                 </div>
                                 <div class="col-2">
                                    <div class="load-box"></div>
                                 </div>
                                 <div class="col-4">
                                    <div class="load-box"></div>
                                 </div>
                                 <div class="col-4">
                                    <div class="load-box"></div>
                                 </div>
                              </div>
                              <hr>
                              <div class="row">
                                 <div class="col-2">
                                    <div class="load-box"></div>
                                 </div>
                                 <div class="col-2">
                                    <div class="load-box"></div>
                                 </div>
                                 <div class="col-4">
                                    <div class="load-box"></div>
                                 </div>
                                 <div class="col-4">
                                    <div class="load-box"></div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>




       <!-- Extra large modal -->


<div class="modal fade nuevo" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
     

              <div class="card-body card-block">
                       
                       <div class="row">

                                <div class="col-sm-6">
                                    
                                  <div class="col-sm-6">Costos Directos</div>
                                  
                                  <table class="table" id="directos">
                                    
                                  </table>


                                </div>

                          <div class="col-sm-6">

                            <div class="col-sm-6">Costos indirectos</div>

                                <table class="table" id="indirectos">
                              
                                 </table>
                           </div>
                    </div>

              </div>

              <div class="card-footer">
                <div class="row">
                    <div class="col-sm-12"><button  class="btn btn-block btn-danger" data-dismiss="modal" id="cerrar">Cerrar</button></div>
                </div>  
              </div>



        </div>
    </div>
  </div>





</template>
<script>
import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

export default {
  name: 'Empresas',


  data() {
    return {
      breadcrumbA: 'Inicio',

      breadcrumbB: 'Empresas Liquidación Diciembre 2021',

      descripcion: 'En este modulo usted podra visualizar y administrar personal en todas nuestras operaciones',
      operaciones: []
    }
  },

  async mounted() {


    const axiosrequest1 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
    const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');

    const axiosrequest3 = axios.get('http://808107d2d15a.sn.mynetname.net:8547/api-int/public/api/nominacostos/585');


    


    await axios.all([axiosrequest1, axiosrequest2, axiosrequest3]).then(axios.spread((operaciones, distribucion, costos) => {

      
      var personal = 0;

      var lista = [];

      const total_costos = (id) => {
        var total = 0;
        $.each(costos.data, function (clave, value) {

          if (value.datos.legajo == id) {
            if (value.costo_laboral.monto != undefined) {
              total = value.costo_laboral.monto;
            }

          }

        })

        return Math.round(total);

      }


     


      const total_consumos = (id) => {

        var almuerzo = 0;
        var cena = 0;
        var viandas= 0;
        var total = 0;

        $.each(JSON.parse(sessionStorage.getItem("consumos")), function (clave, value) {

          if(value.empresa_id==id){
           

            if (value.tipo_empresa=="CATERING") {
                total = value.Servicios.VIANDAS;
            }

            if (value.tipo_empresa=="SALIDA") {
              
              if (value.Servicios.ALMUERZO!=undefined) {
                 almuerzo = parseInt(value.Servicios.ALMUERZO);
               }else{
                almuerzo = 0;
               }

               if (value.Servicios.CENA!=undefined) {
                 cena =  parseInt(value.Servicios.CENA);
               }else{
                cena = 0;
               }

               if (value.Servicios.VIANDAS!=undefined) {
                 viandas = parseInt(value.Servicios.VIANDAS);
               }else{
                viandas = 0;
               }
               
                
                var acv = parseInt(almuerzo+cena+viandas);
                total = acv
            }

          }
          
        })

        return Math.round(total);
}

      

      
      $.each(operaciones.data, function (clave, valor) {

            var costoLaboral = [];
            var costoLaboraldetalle = [];
            var sectores_data = sessionStorage.getItem("sectores");
            var total_catering = sessionStorage.getItem("total_catering");
            var total_salida = sessionStorage.getItem("total_salida");
            var total_servicios = parseInt(total_catering)+parseInt(total_salida);

          
           
            var key_distribucion = Object.keys(distribucion.data).filter(key => key == valor.id);

            var suma = 0;
            if (distribucion.data[key_distribucion[0]] != null) {


              personal = Object.keys(distribucion.data[key_distribucion[0]]).length;

              $.each(distribucion.data[key_distribucion[0]], function (key, value) {
                costoLaboral.push(total_costos(value.legajo))
                costoLaboraldetalle.push({"legajo":value.legajo,"nombre":value.nombre,"costo":total_costos(value.legajo)})
              });

              sessionStorage.setItem("directo-"+key_distribucion,JSON.stringify(costoLaboraldetalle))

              costoLaboral.forEach(function (numero) {
                suma += numero;
              });


            }

            var num = suma.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
            num = num.split('').reverse().join('').replace(/^[.]/, '');


            /* FORMULA  INDIRECTOS CALIDAD*/
            var formula_indirecta = "";
            var formula_consumos = 0;
            var formula_total = 0;
           
            var resultado = 0;

            var indirecto_calidad = 0;
            //var indirecto_capacitacion = 0;
            var indirecto_mantenimiento = 0;
            var indirecto_coordinacion = 0;
            var indirecto_nutricion = 0;
            var indirecto_cocina = 0;
           

            //var indirecto_armado = 0;
            
            var indirecto_gourmet = 0;
            var indirecto_marketing = 0;
            var indirecto_legales = 0;
            var indirecto_costos = 0;
            var indirecto_seleccion = 0;
            var indirecto_rrhh = 0;
            var indirecto_tesoreria = 0;
            var indirecto_direccion = 0;
            var indirecto_facturacion = 0;
            var indirecto_proveedores = 0;
            var indirecto_sistemas = 0;
            var indirecto_compras = 0;
            var indirecto_contabilidad = 0;
            var indirecto_temporal = 0;



            
            var calidad = JSON.parse(sectores_data).filter((el) => el.id == '000544');
            var calidad_formula_catering = (parseInt(calidad[0].costo)*0.56);
            var calidad_formula_salida = (parseInt(calidad[0].costo)*0.44);

           /* var capacitacion = JSON.parse(sectores_data).filter((el) => el.id == '100111');
            var capacitacion_formula_catering = (parseInt(capacitacion[0].costo)*0.39);
            var capacitacion_formula_salida = (parseInt(capacitacion[0].costo)*0.61);
*/
            var mantenimiento = JSON.parse(sectores_data).filter((el) => el.id == '999998');
            var mantenimiento_formula_catering = (parseInt(mantenimiento[0].costo)*0.80);
            var mantenimiento_formula_salida = (parseInt(mantenimiento[0].costo)*0.20);

            var coordinacion = JSON.parse(sectores_data).filter((el) => el.id == '000554');
            var coordinacion_formula_total = (parseInt(coordinacion[0].costo));

            var nutricion = JSON.parse(sectores_data).filter((el) => el.id == '001016');
            var nutricion_formula_total = (parseInt(nutricion[0].costo));

            var cocina = JSON.parse(sectores_data).filter((el) => el.id == '999995');
            var cocina_formula_catering = (parseInt(cocina[0].costo));

            



            /*
            var armado_empotado = JSON.parse(sectores_data).filter((el) => el.id == '033503144');
            var armado_empotado_formula_catering = (parseInt(armado_empotado[0].costo));

            var armado_granel = JSON.parse(sectores_data).filter((el) => el.id == '033503155');
            var armado_granel_formula_catering = (parseInt(armado_granel[0].costo));

            var armado_comun = JSON.parse(sectores_data).filter((el) => el.id == '033503166');
            var armado_comun_formula_catering = (parseInt(armado_comun[0].costo));

            var armado_sap = JSON.parse(sectores_data).filter((el) => el.id == '033503177');
            var armado_sap_formula_catering = (parseInt(armado_sap[0].costo));
            
            */





            var gourmet = JSON.parse(sectores_data).filter((el) => el.id == '999992');
            var gourmet_formula_catering = (parseInt(gourmet[0].costo));

        

            var marketing = JSON.parse(sectores_data).filter((el) => el.id == '000558');
            var marketing_formula_catering = (parseInt(marketing[0].costo));

            var legales = JSON.parse(sectores_data).filter((el) => el.id == '000563');
            var legales_formula_catering = (parseInt(legales[0].costo));

            var costos = JSON.parse(sectores_data).filter((el) => el.id == '000559');
            var costos_formula_catering = (parseInt(costos[0].costo));

            var seleccion = JSON.parse(sectores_data).filter((el) => el.id == '000564');
            var seleccion_formula_catering = (parseInt(seleccion[0].costo));

            var rrhh = JSON.parse(sectores_data).filter((el) => el.id == '000555');
            var rrhh_formula_catering = (parseInt(rrhh[0].costo));

            var tesoreria = JSON.parse(sectores_data).filter((el) => el.id == '000555');
            var tesoreria_formula_catering = (parseInt(tesoreria[0].costo));

            var direccion = JSON.parse(sectores_data).filter((el) => el.id == '000562');
            var direccion_formula_catering = (parseInt(direccion[0].costo));

            var facturacion = JSON.parse(sectores_data).filter((el) => el.id == '000553');
            var facturacion_formula_catering = (parseInt(facturacion[0].costo));

            var proveedores = JSON.parse(sectores_data).filter((el) => el.id == '999994');
            var proveedores_formula_catering = (parseInt(proveedores[0].costo));

            var sistemas = JSON.parse(sectores_data).filter((el) => el.id == '100112');
            var sistemas_formula_catering = (parseInt(sistemas[0].costo));

            var compras = JSON.parse(sectores_data).filter((el) => el.id == '000556');
            var compras_formula_catering = (parseInt(compras[0].costo));

            var contabilidad = JSON.parse(sectores_data).filter((el) => el.id == '999996');
            var contabilidad_formula_catering = (parseInt(contabilidad[0].costo));

            var temporal = JSON.parse(sectores_data).filter((el) => el.id == '999999');
            var temporal_formula_catering = (parseInt(temporal[0].costo));
            
            



            if (valor.tipo_empresa=="CATERING") {
              
              formula_consumos = (total_consumos(valor.id)/parseInt(total_catering))*100;
              formula_total = (total_consumos(valor.id)/parseInt(total_servicios))*100;



              indirecto_calidad = (calidad_formula_catering*formula_consumos)/100;
              //indirecto_capacitacion = (capacitacion_formula_catering*formula_consumos)/100;
              indirecto_mantenimiento = (mantenimiento_formula_catering*formula_consumos)/100;
              indirecto_coordinacion = (coordinacion_formula_total*formula_total)/100;
              indirecto_nutricion = (nutricion_formula_total*formula_total)/100;
              indirecto_cocina = (cocina_formula_catering*formula_consumos)/100;
              //indirecto_armado = (armado_formula_catering*formula_consumos)/100;
              indirecto_gourmet = (gourmet_formula_catering*formula_consumos)/100;

              indirecto_marketing = (marketing_formula_catering*formula_total)/100;
              indirecto_legales = (legales_formula_catering*formula_total)/100;
              indirecto_costos = (costos_formula_catering*formula_total)/100;
              indirecto_seleccion = (seleccion_formula_catering*formula_total)/100;
              indirecto_rrhh = (rrhh_formula_catering*formula_total)/100;
              indirecto_tesoreria = (tesoreria_formula_catering*formula_total)/100;
              indirecto_direccion = (direccion_formula_catering*formula_total)/100;
              indirecto_facturacion = (facturacion_formula_catering*formula_total)/100;
              indirecto_proveedores = (proveedores_formula_catering*formula_total)/100;
              indirecto_sistemas = (sistemas_formula_catering*formula_total)/100;
              indirecto_compras = (compras_formula_catering*formula_total)/100;
              indirecto_contabilidad = (contabilidad_formula_catering*formula_total)/100;
              indirecto_temporal = (temporal_formula_catering*formula_total)/100;


              formula_indirecta =  "<tr><td>CALIDAD/PLANTA</td><td> 56% </td><td>$"+Math.round(indirecto_calidad)+"</td></tr>";
              //formula_indirecta += "<tr><td>CAPACITACIÓN/PLANTA</td><td> 39%  </td><td>$"+Math.round(indirecto_capacitacion)+"</td></tr>";
              formula_indirecta += "<tr><td>MANTENIMIENTO/PLANTA</td><td> 80%  </td><td>$"+Math.round(indirecto_mantenimiento)+"</td></tr>";
              formula_indirecta += "<tr><td>COORDINACIÓN/PLANTA</td><td> 100% </td><td>$"+Math.round(indirecto_coordinacion)+"</td></tr>";
              formula_indirecta += "<tr><td>NUTRICIÓN/PLANTA</td><td> 100%  </td><td>$"+Math.round(indirecto_nutricion)+"</td></tr>";
              formula_indirecta += "<tr><td>COCINA/PLANTA</td><td> 100%  </td><td>$"+Math.round(indirecto_cocina)+"</td></tr>";
             // formula_indirecta += "<tr><td>ARMADO/PLANTA</td><td> 100%  </td><td>$"+Math.round(indirecto_armado)+"</td></tr>";
              formula_indirecta += "<tr><td>COCINA COURMET/PLANTA</td><td> 100%  </td><td>$"+Math.round(indirecto_gourmet)+"</td></tr>";

              formula_indirecta += "<tr><td>MARKETING/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_marketing)+"</td></tr>";
              formula_indirecta += "<tr><td>LEGALES/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_legales)+"</td></tr>";
              formula_indirecta += "<tr><td>COSTOS/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_costos)+"</td></tr>";
              formula_indirecta += "<tr><td>SELECCIÓN/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_seleccion)+"</td></tr>";
              formula_indirecta += "<tr><td>RRHH/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_rrhh)+"</td></tr>";
              formula_indirecta += "<tr><td>TESORERÍA/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_tesoreria)+"</td></tr>";
              formula_indirecta += "<tr><td>DIRECCIÓN/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_direccion)+"</td></tr>";
              formula_indirecta += "<tr><td>FACTURACIÓN/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_facturacion)+"</td></tr>";
              formula_indirecta += "<tr><td>PROVEEDORES/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_proveedores)+"</td></tr>";
              formula_indirecta += "<tr><td>SISTEMAS/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_sistemas)+"</td></tr>";
              formula_indirecta += "<tr><td>COMPRAS/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_compras)+"</td></tr>";
              formula_indirecta += "<tr><td>CONTADURÍA/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_contabilidad)+"</td></tr>";
              formula_indirecta += "<tr><td>TEMPORAL/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_temporal)+"</td></tr>";

              sessionStorage.setItem('formula-'+valor.id,formula_indirecta);

              //resultado = parseInt(indirecto_calidad+indirecto_capacitacion+indirecto_mantenimiento+indirecto_coordinacion+indirecto_nutricion+indirecto_cocina+indirecto_armado+indirecto_gourmet+indirecto_marketing+indirecto_legales+indirecto_costos+indirecto_seleccion+indirecto_rrhh+indirecto_tesoreria+indirecto_direccion+indirecto_facturacion+indirecto_proveedores+indirecto_sistemas+indirecto_compras+indirecto_contabilidad+indirecto_temporal);

              resultado = parseInt(indirecto_calidad+indirecto_mantenimiento+indirecto_coordinacion+indirecto_nutricion+indirecto_cocina+indirecto_gourmet+indirecto_marketing+indirecto_legales+indirecto_costos+indirecto_seleccion+indirecto_rrhh+indirecto_tesoreria+indirecto_direccion+indirecto_facturacion+indirecto_proveedores+indirecto_sistemas+indirecto_compras+indirecto_contabilidad+indirecto_temporal);


            }
            if (valor.tipo_empresa=="COMEDOR") {
              formula_consumos = (total_consumos(valor.id)/parseInt(total_salida))*100;
              formula_total = (total_consumos(valor.id)/parseInt(total_servicios))*100;

              indirecto_calidad = (calidad_formula_salida*formula_consumos)/100;
              //indirecto_capacitacion = (capacitacion_formula_salida*formula_consumos)/100;
              indirecto_mantenimiento = (mantenimiento_formula_salida*formula_consumos)/100;
              indirecto_coordinacion = (coordinacion_formula_total*formula_total)/100;
              indirecto_nutricion = (nutricion_formula_total*formula_total)/100;

              indirecto_marketing = (marketing_formula_catering*formula_total)/100;
              indirecto_legales = (legales_formula_catering*formula_total)/100;
              indirecto_costos = (costos_formula_catering*formula_total)/100
              indirecto_seleccion = (seleccion_formula_catering*formula_total)/100;
              indirecto_rrhh = (rrhh_formula_catering*formula_total)/100;
              indirecto_tesoreria = (tesoreria_formula_catering*formula_total)/100;
              indirecto_direccion = (direccion_formula_catering*formula_total)/100;
              indirecto_facturacion = (facturacion_formula_catering*formula_total)/100;
              indirecto_proveedores = (proveedores_formula_catering*formula_total)/100;
              indirecto_sistemas = (sistemas_formula_catering*formula_total)/100;
              indirecto_compras = (compras_formula_catering*formula_total)/100;
              indirecto_contabilidad = (contabilidad_formula_catering*formula_total)/100;
              indirecto_temporal = (temporal_formula_catering*formula_total)/100;


              formula_indirecta =  "<tr><td>CALIDAD/PLANTA</td><td> 44%</td><td>$"+Math.round(indirecto_calidad)+"</td></tr>";
              
              //formula_indirecta += "<tr><td>CAPACITACIÓN/PLANTA</td><td> 61%</td><td> $"+Math.round(indirecto_capacitacion)+"</td></tr>";
              formula_indirecta += "<tr><td>MANTENIMIENTO/PLANTA</td><td> 20%</td><td> $"+Math.round(indirecto_mantenimiento)+"</td></tr>";
              formula_indirecta += "<tr><td>COORDINACIÓN/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_coordinacion)+"</td></tr>";
              formula_indirecta += "<tr><td>NUTRICIÓN/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_nutricion)+"</td></tr>";

              formula_indirecta += "<tr><td>MARKETING/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_marketing)+"</td></tr>";

              formula_indirecta += "<tr><td>LEGALES/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_legales)+"</td></tr>";

              formula_indirecta += "<tr><td>COSTOS/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_costos)+"</td></tr>";
              
              formula_indirecta += "<tr><td>SELECCIÓN/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_seleccion)+"</td></tr>";

              formula_indirecta += "<tr><td>RRHH/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_rrhh)+"</td></tr>";

              formula_indirecta += "<tr><td>TESORERÍA/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_tesoreria)+"</td></tr>";

              formula_indirecta += "<tr><td>DIRECCIÓN/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_direccion)+"</td></tr>";

              formula_indirecta += "<tr><td>FACTURACIÓN/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_facturacion)+"</td></tr>";

              formula_indirecta += "<tr><td>PROVEEDORES/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_proveedores)+"</td></tr>";

              formula_indirecta += "<tr><td>SISTEMAS/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_sistemas)+"</td></tr>";

              formula_indirecta += "<tr><td>COMPRAS/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_compras)+"</td></tr>";

              formula_indirecta += "<tr><td>CONTADURÍA/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_contabilidad)+"</td></tr>";

              formula_indirecta += "<tr><td>TEMPORAL/PLANTA</td><td> 100%</td><td> $"+Math.round(indirecto_temporal)+"</td></tr>";

              
              sessionStorage.setItem('formula-'+valor.id,formula_indirecta);

              resultado = parseInt(indirecto_calidad+indirecto_mantenimiento+indirecto_coordinacion+indirecto_nutricion+indirecto_marketing+indirecto_legales+indirecto_costos+indirecto_seleccion+indirecto_rrhh+indirecto_tesoreria+indirecto_direccion+indirecto_facturacion+indirecto_proveedores+indirecto_sistemas+indirecto_compras+indirecto_contabilidad+indirecto_temporal);

            }
            /* CIERRA FORMULA  INDIRECTOS CALIDAD*/

           var total = parseInt(suma+Math.round(resultado)).toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
           total = total.split('').reverse().join('').replace(/^[.]/, '');


            var total_indi = resultado.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
           total_indi = total_indi.split('').reverse().join('').replace(/^[.]/, '');
            

            if (suma != 0) {

              lista.push({
                "id": valor.id,
                "centroapropiacion_id": valor.centroapropiacion_id,
                "codigo_costos": valor.codigo_costos,
                "name": valor.name,
                "tipo_empresa": valor.tipo_empresa,
                "personal": personal,
                "costo": num.replace(",", "."),
                "indirecto":total_indi,
                "total":total
              })
            }

      });

          
      
      this.operaciones = lista;
      $("#loading").hide();


    })).then(() => {

      $('#empresas').DataTable({

        "search": {
          regex: true
        },
        dom: 'Bfrtip',

        buttons: [
          'copy',
          'excel',
          'csv',
          'pdf'
        ]

      });
    });
  },
 methods:{
  detalle(id){


    $("#directos").empty();
    $("#indirectos").empty();
    $("#directos").append("<tr><td>Legajo</td><td>Nombre</td><td>Categoria</td><td>Costo</td></tr>");
    $("#indirectos").append("<tr><td>Sector</td><td>Distribución</td><td>Total</td></tr>")
    $.each(JSON.parse(sessionStorage.getItem("directo-"+id)),function(clave,valor){
       var costo = valor.costo.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.').split('').reverse().join('').replace(/^[.]/, '').replace(",", ".");
       
      $("#directos").append("<tr><td>"+valor.legajo+"</td><td>"+valor.nombre+"</td><td>-</td><td>$"+costo+"</td></tr>");
     


    });
       

       $("#indirectos").append(sessionStorage.getItem('formula-'+id))
         

  }
 }


};
</script>
<style scoped>


   .load-box {
   background: red;
   }
   @keyframes placeHolderShimmer {
   0% {
   background-position: 0px 0;
   }
   100% {
   background-position: 100em 0;
   }
   }
   .load-box {
   animation-duration: 4s;
   animation-fill-mode: forwards;
   animation-iteration-count: infinite;
   animation-name: placeHolderShimmer;
   animation-timing-function: linear;
   background: fff;
   background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
   height: 24px;
   -webkit-backface-visibility: hidden;
   left:0;
   right:0;
   top:0;
   bottom:0;
   }
</style>
