import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'jquery/dist/jquery.min.js'
                                                                                                                                                                                                                                                                    
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// createApp(App).use(BootstrapVue);
// createApp(App).use(IconsPlugin);


// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

createApp(App).use(router).mount('#app')


