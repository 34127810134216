<template>

  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                           
                            </p>



                        </div>  
                      </div>
                    </div>
                  </div>    



                  <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                
                <div class="table-responsive">
                  
                  

                  <table id="empresas" class="table">
                    <thead>
                      <tr>
                       
                        <th>Cod</th>
                       
                        <th>Nombre</th>
                        
                    
                        <th>Per</th>
                        <th>Costo</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr  v-for="operaciones in operaciones"  v-bind:key="operaciones.id" >
                        
                        <td>{{operaciones.id}}</td>
                        
                        <td>{{operaciones.name}}</td>
                       
                        
                        <td>{{operaciones.personal}}</td>
                        <td>$ {{operaciones.costo}}</td>
                       
                      </tr>

                    </tbody>
                  </table>

                     <div class="col-md-12 grid-margin stretch-card" id="loading">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-title w-25"><div class="load-box"></div></div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>



import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

export default {
    name: 'Empresas',


    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Sectores',
            descripcion: 'En este modulo usted podra visualizar el costo demano de obra de los sectores de Planta',
            operaciones: []
        }
    },

    async mounted() {


        const axiosrequest1 = axios.get('https://aplicativos.firebaseio.com/sectores.json?print=pretty');
        const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');

        const axiosrequest3 = axios.get('http://808107d2d15a.sn.mynetname.net:8547/api-int/public/api/nominacostos/585');


        
        await axios.all([axiosrequest1, axiosrequest2, axiosrequest3]).then(axios.spread((operaciones, distribucion,costos) => {

           
            var personal = 0;
            var lista = [];

            const total_costos = (id) => {
                var total = 0;
                $.each(costos.data, function(clave, value) {

                     if (value.datos.legajo==id) {
                       if (value.costo_laboral.monto!=undefined) {
                          total =  value.costo_laboral.monto;
                       }
                        
                     }
                     
                })

                return Math.round(total);
               
            }
            


            $.each(operaciones.data, function(clave, valor) {

               var costoLaboral = [];
              
               var key_distribucion = Object.keys(distribucion.data).filter(key => key == valor.id_empresa);

                 var suma = 0;
                if (distribucion.data[key_distribucion[0]] != null) {


                    personal = Object.keys(distribucion.data[key_distribucion[0]]).length;

                   $.each(distribucion.data[key_distribucion[0]],function(key,value){
                    costoLaboral.push(total_costos(value.legajo))
                   });

                  
                    costoLaboral.forEach(function(numero){
                      suma += numero;
                    });

                  
                    
                }


               var num = suma.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
               num = num.split('').reverse().join('').replace(/^[.]/,'');
               

                if (suma!=0) {
                   
                    lista.push({
                        "id": valor.id,
                        "name": valor.operacion,
                        
                        "personal": personal,
                        "costo":num.replace(",",".")
                    })
                }

            });


            this.operaciones = lista;
            $("#loading").hide();




        })).then(() => {
            $('#empresas').DataTable({
            
               "search": {regex:true},
                dom: 'Bfrtip',
                
                buttons: [
                    'copy',
                    'excel',
                    'csv',
                    'pdf'
                ]

            });
        });






    },
    



};

</script>


<style scoped>
.load-box {
    background: red;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
}
</style>
