<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}</p>



                            <div class="alert alert-danger" role="alert" id="sinreg">
                              NO EXISTEN REGISTROS GUARDADOS! PUEDE GUARDAR REGISTROS EN EL MODULO "AUTORIZADOS AGRUPADOS" DENTRO DE PROYECCIÒN BASICO
                            </div>
                                        
                    <div class="row ocultar">

                                <div class="col-md-4">
                                                            <select id="liquidacion-a"></select>

                            </div>

    


                             <div class="col-md-6">
                                 
                                  <button class="btn btn-primary mr-2 mb-2 mb-md-0 text-white" v-on:click="comparar()">PROYECTAR</button>
                             </div>


                            </div>
<!--
                            <br><br>
                            
                            <table class="table ocultar" width="100%">
                             
                                  <tr>
                                      <td colspan="2" style="background-color:#d6daad;" class="fecha1">Diciembre 2021</td>
                                      <td colspan="2" style="background-color:#e2b79d;" class="">Proyecciòn</td>
                                      <td  style="background-color:#ea9460;">Observacion</td>
                                  </tr>  

                                  <tr>
                                      <td style="background-color:#d6daad;">Personal</td>
                                      <td style="background-color:#d6daad;">Costo total</td>

                                      <td style="background-color:#e2b79d;">Personal</td>
                                      <td style="background-color:#e2b79d;">Costo total</td>

                                      <td style="background-color:#ea9460;">Diferencia</td>
                                  </tr>  

                                    <tr>
                                      <td style="background-color:#d6daad;" id="personal_a">0</td>
                                      <td style="background-color:#d6daad;" id="total_a">$0</td>

                                      <td style="background-color:#e2b79d;"><span id="personal_b">0</span>  <span id="porc_per_a"></span></td>
                                      <td style="background-color:#e2b79d;"><span id="total_b">$0</span> <span id="porc_cos_a"></span></td>

                                      <td style="background-color:#ea9460;"><span id="diferencia_t">$0</span> <span id="porc_dif"></span></td>
                                  </tr> 

                            </table>

                        -->


                        </div>  
                      </div>


                    </div>
                  </div>    



                  <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                

  <!--<div id="container" style="width:100%; height:400px;"></div>-->

<br><br>
   <center>
     <!--
<div style="width:100%;display: none;" id="botones">
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="">TODOS</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="operaciones">OPERACIONES</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="Administración">ADMINISTRACION</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="comercial">COMERCIAL</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="jefe">JEFES</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="gerente">GERENTES</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="regional">REGIONALES</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="zonal">ZONALES</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="pasante">PASANTES</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="- 523">SUP 1</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="- 563">SUP 2</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="- 564">SUP 3</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="- 562">SUP PT</button>
</div>
 <br>-->
    <div id="spreadsheet"></div></center>




<div class="col-md-12 grid-margin stretch-card" id="loading">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-title w-25"><div class="load-box"></div></div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
              
              </div>
            </div>
          </div>
        </div>


            </div>
     

</template>

<script>


 import Swal from 'sweetalert2';



import $ from 'jquery';
import axios from 'axios';
import 'select2';

var Highcharts = require('highcharts');

import jspreadsheet from 'jspreadsheet-ce';
var numeral = require('numeral');
var liquidacion = "";
var datos_guardados = "";
var costo_guardado="";
var porc_guardado="";

var diferencia_t="";
var porc_dif="";
var txt ="";
var selector = [];
 const addZero=(i)=>{
                    if (i < 10) {
                        i = "0" + i;
                    }
                    return i;
                }
export default {
    name: 'Personal',


    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'AUMENTOS Y PORCENTAJES GUARDADOS',
            descripcion: 'En este modulo usted podra visualizar todas las proyecciones y aumentos guardados',
            personal: [],
        }
    },

    async mounted() {






    const axios1 = axios.get('https://aplicativos.firebaseio.com/liquidacion_recibos/AME.json');
    const axios2 = axios.get('https://aplicativos.firebaseio.com/liquidacion_recibos/CAT.json');
       

    await axios.all([axios1, axios2]).then(axios.spread((america, catering) => {

var liquidaciones = [];


$.each(america.data,function(clave,valor){
    liquidaciones.push({"anio":valor.anio,"liquidacion_america":valor.liquidacion,"mes":valor.mes,"option":valor.option})
    
 });

$.each(catering.data,function(clave,valor){
    liquidaciones.push({"anio":valor.anio,"liquidacion_catering":valor.liquidacion,"mes":valor.mes,"option":valor.option})
    
 });

const mergedData = Object.values(liquidaciones.reduce((acc, curr) => {
  const caption = curr.option.split(" ")[0];
  if (!acc[caption]) {
    acc[caption] = [];
  }
  acc[caption].push(curr);
  return acc;
}, {})).map(group => group.reduce((acc, curr) => Object.assign(acc, curr), {}));


localStorage.setItem("liquidaciones",JSON.stringify(mergedData));


    var count = 0;
    var limite = Object.keys(mergedData).length;
     $.each(mergedData,function(clave,valor){
        count++

        var mes = valor.mes;
        if (mes<10) {
            mes="0"+mes
        }
        
        if (count==limite) {

            $("#liquidacion-a").prepend('<option selected="true" value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            $("#liquidacion-b").prepend('<option selected="true" data-fecha="'+valor.anio+'-'+mes+'-28" value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            selector.push({ [valor.mes+'-'+valor.anio+'-'+valor.liquidacion_america+'-'+valor.liquidacion_catering]:valor.option })

        }else{

            $("#liquidacion-a").prepend('<option value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            $("#liquidacion-b").prepend('<option data-fecha="'+valor.anio+'-'+mes+'-28" value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            selector.push({ [valor.mes+'-'+valor.anio+'-'+valor.liquidacion_america+'-'+valor.liquidacion_catering]:valor.option })

        }
        
     

     });




})).then(() => {


            
$("#loading").hide();
$(".table").hide();
               

        });


    },
     methods: {
        guardar() {

               
                    (async function() {


        const userData = JSON.parse(sessionStorage.getItem('userData'));
        var h = new Date();
        var hora = addZero(h.getHours());
        var minutos = ("0" + h.getMinutes()).substr(-2);
        var segundos = ("0" + h.getSeconds()).substr(-2);
        var horaActual = hora + ":" + minutos + ":" + segundos;

        var hoy = new Date();

        var m = hoy.getMonth() + 1;
        var mes = m < 10 ? "0" + m : m;
        var dia = hoy.getDate();
        dia = dia < 10 ? "0" + dia : dia;
        var fechaActualok = dia + "-" + mes + "-" + hoy.getFullYear();
        var fechaActualok_b = hoy.getFullYear() + "-" + mes + "-" + dia;




         

            


})();

           
         },

        removeDuplicates(originalArray, prop) {
             var newArray = [];
             var lookupObject  = {};

             for(var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
             }

             for(i in lookupObject) {
                 newArray.push(lookupObject[i]);
             }
              return newArray;
         },

         calcular(legajo){

             const moneda = (numero) => {
              const formatoMoneda = new Intl.NumberFormat('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 0, // Establecer el número mínimo de dígitos decimales a cero
                maximumFractionDigits: 0, // Establecer el número máximo de dígitos decimales a cero
                currencyDisplay: 'narrowSymbol' // Opción para eliminar el símbolo de moneda
              });

              const numeroFormateado = formatoMoneda.format(numero);

              return numeroFormateado;
            }


                  var per = parseFloat($("#basico_b-"+legajo).val());
                  var basico = parseFloat($("#basico_a-"+legajo).html());
                  var cuenta = (basico*per)/100;
                  var total = parseFloat(basico+cuenta).toFixed(3);





                    if (isNaN(total)||total==0) {
                      total = 0;
                      $("#proyeccion_a-"+legajo).html(0)
                    } else {
                      $("#proyeccion_a-"+legajo).html(total)
                    }


                    //DIFERENCIA

                    var direfencia = total-basico;
                    console.log(direfencia)

                    if (isNaN(direfencia)||direfencia<0) {
                      
                      $("#diferencia-"+legajo).html(0)
                      sessionStorage.setItem("total-"+legajo,0)
                    } else {
                      $("#diferencia-"+legajo).html(moneda(direfencia.toFixed(3).replace(".","")))
                      sessionStorage.setItem("total-"+legajo,direfencia.toFixed(3).replace(".",""))
                    }



   var table = $('#empresas').DataTable();                     
  // Obtener los datos de la columna "País"
  var data = table.column(0).data();

  // Crear un array de valores únicos de la columna "País"
  var uniqueData = data.unique();

  
  var sumar = 0;
  // Agregar las opciones del filtro select con los valores únicos de la columna "País"
  $.each(uniqueData, function(i, val) {


console.log(liquidacion)
    if (sessionStorage.getItem("total-"+val) !== null) {
        sumar+=parseFloat(sessionStorage.getItem("total-"+val));

       

    }
    
        
    
  });

  console.log("SUMA == "+sumar)


const numeroMoneda = localStorage.getItem("costo");
const numeroEntero = parseFloat(numeroMoneda.replace(/\$|\.+/g, ''));
var costo=moneda(numeroEntero+sumar); // output: 112337086
var costo_entero=numeroEntero+sumar;
//$("#total_b").html(costo)                   

 

 var porcentajeAumento = ((costo_entero - numeroEntero) / numeroEntero) * 100;
 var porcentaje = parseFloat(porcentajeAumento.toFixed(2));
 console.log("PORCENTAJE :: "+porcentaje)

 var resta = costo_entero-numeroEntero;

if (porcentaje>0.00) {
      //$("#porc_cos_a").html('<span class="arraw igual badge badge-danger"> %'+porcentaje+' ↑</span>')   
}else{
     //$("#porc_cos_a").html('<span class="arraw igual badge badge-light"> %'+porcentaje+' ↔</span>')   
}

if (costo_entero!=numeroEntero) {
      $("#diferencia_t").html("$"+moneda(resta))
      $("#porc_dif").html('<span class="arraw igual badge badge-danger">↑</span>')   
}else{
     $("#diferencia_t").html("$"+moneda(resta))
     $("#porc_dif").html('<span class="arraw igual badge badge-light">↔</span>')     
}


                


                  
        },

        async comparar(){

            $("#loading").show();

            

            const moneda = (numero) => {
              const formatoMoneda = new Intl.NumberFormat('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 0, // Establecer el número mínimo de dígitos decimales a cero
                maximumFractionDigits: 0, // Establecer el número máximo de dígitos decimales a cero
                currencyDisplay: 'narrowSymbol' // Opción para eliminar el símbolo de moneda
              });

              const numeroFormateado = formatoMoneda.format(numero);

              return numeroFormateado;
            }


          
        $(".table").show();
        $("#alerta").hide(); 

        $("#guardar").show(); 
         $("#botones").show(); 

        $('#spreadsheet').html("");

         var liq_1_a = $("#liquidacion-a option:selected").val().split("-")[0];
         var liq_1_b = $("#liquidacion-a option:selected").val().split("-")[1];
         
         localStorage.setItem("costo_liq",liq_1_a+'-'+liq_1_b);

         $(".fecha1").html($("#liquidacion-a option:selected").text())
         $(".fecha2").html( $("#liquidacion-b option:selected").text())

        const data = JSON.parse(localStorage.getItem("liquidaciones"));
        const ultimoResultado = data[data.length - 1];
        const liquidacionAmericaUltimoResultado = ultimoResultado["liquidacion_america"];
        const liquidacionCateringUltimoResultado = ultimoResultado["liquidacion_catering"];
        const ultimoMes = ultimoResultado["option"];

        console.log(liquidacionAmericaUltimoResultado)
        console.log(liquidacionCateringUltimoResultado)
         

        const axiosrequest1 = axios.get('https://auth.foodservice.com.ar/?type=personal&access_token=1234567');
        const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/categorias.json?print=pretty');

        
        const axiosrequest3 = axios.get('https://auth.foodservice.com.ar/?type=costos_ame&liquidacion='+liq_1_a+'&access_token=1234567');
        const axiosrequest4 = axios.get('https://auth.foodservice.com.ar/?type=costos_cat&liquidacion='+liq_1_b+'&access_token=1234567');

        const axiosrequest5 = axios.get('https://aplicativos.firebaseio.com/proyecciones/'+liq_1_a+'-'+liq_1_b+'.json');



        const axiosrequest6 = axios.get('https://auth.foodservice.com.ar/?type=costos_ame&liquidacion='+liquidacionAmericaUltimoResultado+'&access_token=1234567');
        const axiosrequest7 = axios.get('https://auth.foodservice.com.ar/?type=costos_cat&liquidacion='+liquidacionCateringUltimoResultado+'&access_token=1234567');

        const axiosrequest8 = axios.get('https://aplicativos.firebaseio.com/legales.json?print=pretty');

        const axiosrequest9 = axios.get('https://aplicativos.firebaseio.com/requisitoria_salarial/'+liq_1_a+'-'+liq_1_b+'.json');

        const axiosrequest10 = axios.get('https://aplicativos.firebaseio.com/ajuste_salarial.json?print=pretty');

        const axiosrequest11 = axios.get('https://aplicativos.firebaseio.com/categorias_cat.json?print=pretty');
       

       await axios.all([axiosrequest1, axiosrequest2, axiosrequest3,axiosrequest4,axiosrequest5,axiosrequest6,axiosrequest7,axiosrequest8,axiosrequest9,axiosrequest10,axiosrequest11]).then(axios.spread((personal, categorias, costo_a,costo_b,proyeccion,costos_pa,costos_pb,legales,ajustes,ajuste_requi,categorias_cat) => {

            
           
            const costox_a = Object.assign({}, costo_a.data, costo_b.data);
            
            const costox_b = Object.assign({}, costos_pa.data, costos_pb.data);
            
            
            $("#personal_a").html(Object.keys(costox_a).length);
            $("#personal_b").html(Object.keys(costox_b).length);


              const convencionado = (id) => {
                    var convenio = "";
                    $.each(personal.data, function(clave, value) {

                        if (value.leg_numero == id) {
                            convenio = value.leg_convencionado;
                        }
                    })
                    return convenio;
                }



             const result_categoria = (id) => {
                var categoria = "";
                $.each(categorias.data, function(clave, value) {

                    if (value.codigo == id) {
                        categoria = value.nombre;
                    }
                })
                return categoria;
            }
            
              var lista = []; 
             $.each(costox_a, function(clave, valor) {


                    if (convencionado(clave)==1) {

                        var catego = result_categoria(valor.datos.cat_tipo)+" - "+valor.datos.cat_tipo;
                       

                         lista.push([
                                        '<div class="categoria-'+valor.datos.cat_tipo+'">'+clave+'</div>',
                                        valor.datos.nombre.split(', ')[0]+" "+valor.datos.nombre.split(', ')[1]+'<br><span style="background-color: transparent;padding: 4px;color: #000;font-size: 8px;">'+catego+'</span><br><span style="background-color: transparent;padding: 4px;color: #000;font-size: 8px;margin-left:0px;">'+localStorage.getItem("operacion-"+localStorage.getItem("ubicacion-"+clave))+'</span>',
                                        valor.datos.basico,
                                        valor.remunerativo.monto,
                                        valor.no_remunerativo.monto,
                                        valor.datos.neto,
                                       ]
                                    )



                    }



             })

             const formatCurrency = (amount) => {
                  return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }  

            var data = lista;

            console.log(lista.length)
            $("#loading").hide();





var table =jspreadsheet(document.getElementById('spreadsheet'), {
   
    data:data,
    search: true,
    pagination: 10,
    setEditable:false,
    columnSorting:false,
   
    
    paginationOptions: [10,25,50],
    filters: false,
     allowComments:true,



    columns: [
        {
            type: 'html',
            title:'Legajo',
            width:50,
            align:"left",
            readOnly:true
        },
        {
            type: 'html',
            title:'Nombre y Apellido',
            width:240,
            align:"left",
            readOnly:true,
        },


       {
            type: 'numeric',
            title:'Basico',
            mask:'$ #.##',
            width:150,
            decimal:',',
            align:"left",
            readOnly:true
        },

         {
            type: 'numeric',
            title:'Remunerativo',
           mask:'$ #.##',
            width:150,
            decimal:',',
            align:"left",
            readOnly:true
        },

         {
            type: 'numeric',
            title:'No remunerativo',
           mask:'$ #.##',
            width:150,
            decimal:',',
            align:"left",
            readOnly:true
        },

         {
            type: 'numeric',
            title:'Neto',
            mask:'$ #.##',
            width:150,
            decimal:',',
            align:"left",
            readOnly:true
        },

     ],
         
 sorting: function(direction, column) {
        return function(a, b) {
            var valueA = a[1];
            var valueB = b[1];
 
            // Consider blank rows in the sorting
            if (! direction) {
                return (valueA > valueB) ? 1 : (valueA < valueB) ? -1 : 0;
            } else {
                return (valueA > valueB) ? -1 : (valueA < valueB) ? 1 : 0;
            }
        }
    },
   
});






setTimeout(function(){

    console.log("ahora si")




    $(".filtro").click(function(){

    var filtro = $(this).data("filtro");
    


    table.search(filtro);


    });

},4000)














        })).then(() => {





$("#loading").hide();
 




})

        }

    },



};
</script>

<style scoped>




.load-box {
    background: red;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
}

.up{
    color:green;
}
.down{
    color:red;
}

.colapse{
    position: absolute;
}

#sinreg{
    display: none;
}


.tablesorter tbody > tr > td > div[contenteditable=true]:focus {
  outline: #08f 1px solid;
  background: #eee;
  resize: none;
}
td.no-edit, span.no-edit {
  background-color: rgba(230,191,153,0.5);
}
.focused {
  color: blue;
}
td.editable_updated {
  background-color: green;
  color: white;
}




table {
  position: relative;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

td, th {
  white-space: nowrap;
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
}

th {
  background-color: #eee;
  position: sticky;
  top: -1px;
  z-index: 2;
  
  &:first-of-type {
    left: 0;
    z-index: 3;
  }
}

tbody tr td:first-of-type {
  background-color: #eee;
  position: sticky;
  left: -1px;
  text-align: left;
}

.comentario {
  background-color: #FFD700; /* Cambia el color de fondo */
  color: #333; /* Cambia el color del texto */
  font-style: italic; /* Cambia el estilo de fuente a cursiva */
}







</style>
