<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}</p>



                            <div class="alert alert-danger" role="alert" id="sinreg">
                              NO EXISTEN REGISTROS GUARDADOS! PUEDE GUARDAR REGISTROS EN EL MODULO "AUTORIZADOS AGRUPADOS" DENTRO DE PROYECCIÒN BASICO
                            </div>
                                        
                    <div class="row ocultar">

                                <div class="col-md-4">
                                                            <select id="liquidacion-a"></select>

                            </div>

    


                             <div class="col-md-6">
                                 
                                  <button class="btn btn-primary mr-2 mb-2 mb-md-0 text-white" v-on:click="comparar()">PROYECTAR</button>
                             </div>


                            </div>
<!--
                            <br><br>
                            
                            <table class="table ocultar" width="100%">
                             
                                  <tr>
                                      <td colspan="2" style="background-color:#d6daad;" class="fecha1">Diciembre 2021</td>
                                      <td colspan="2" style="background-color:#e2b79d;" class="">Proyecciòn</td>
                                      <td  style="background-color:#ea9460;">Observacion</td>
                                  </tr>  

                                  <tr>
                                      <td style="background-color:#d6daad;">Personal</td>
                                      <td style="background-color:#d6daad;">Costo total</td>

                                      <td style="background-color:#e2b79d;">Personal</td>
                                      <td style="background-color:#e2b79d;">Costo total</td>

                                      <td style="background-color:#ea9460;">Diferencia</td>
                                  </tr>  

                                    <tr>
                                      <td style="background-color:#d6daad;" id="personal_a">0</td>
                                      <td style="background-color:#d6daad;" id="total_a">$0</td>

                                      <td style="background-color:#e2b79d;"><span id="personal_b">0</span>  <span id="porc_per_a"></span></td>
                                      <td style="background-color:#e2b79d;"><span id="total_b">$0</span> <span id="porc_cos_a"></span></td>

                                      <td style="background-color:#ea9460;"><span id="diferencia_t">$0</span> <span id="porc_dif"></span></td>
                                  </tr> 

                            </table>

                        -->


                        </div>  
                      </div>


                    </div>
                  </div>    



                  <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                
 <div class="col-md-6">
                             <button id="guardar" style="width:275px;margin-left:30px;display: none;" class="btn btn-danger mr-2 mb-2 mb-md-0 text-white" v-on:click="guardar()">PUBLICAR AUMENTOS SALARIALES</button>
</div>
  <!--<div id="container" style="width:100%; height:400px;"></div>-->
 
<br><br>
   <center>
<div style="width:100%;display: none;" id="botones">
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="">TODOS</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="operaciones">OPERACIONES</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="Administración">ADMINISTRACION</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="comercial">COMERCIAL</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="jefe">JEFES</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="gerente">GERENTES</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="regional">REGIONALES</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="zonal">ZONALES</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="pasante">PASANTES</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="- 523">SUP 1</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="- 563">SUP 2</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="- 564">SUP 3</button>
<button  class="btn btn-info mr-2 mb-2 mb-md-0 text-white  filtro" data-filtro="- 562">SUP PT</button>
</div>
 <br>
    <div id="spreadsheet"></div></center>




<div class="col-md-12 grid-margin stretch-card" id="loading">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-title w-25"><div class="load-box"></div></div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
              
              </div>
            </div>
          </div>
        </div>


            </div>
     

</template>

<script>

 import firebase from "firebase/app";
 import "firebase/database";
 import Swal from 'sweetalert2';

const firedata = firebase.initializeApp({
      // Prod
    apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
    authDomain: "cantina-89c6a.firebaseapp.com",
    databaseURL: "https://aplicativos.firebaseio.com",
    projectId: "cantina-89c6a",
    storageBucket: "cantina-89c6a.appspot.com",
    messagingSenderId: "353062217145",
    appId: "1:353062217145:web:8849a49a1ec52077"

  },
  "firedata"
);


import $ from 'jquery';
import axios from 'axios';
import 'select2';

var Highcharts = require('highcharts');

import jspreadsheet from 'jspreadsheet-ce';
var numeral = require('numeral');
var liquidacion = "";
var datos_guardados = "";
var costo_guardado="";
var porc_guardado="";

var diferencia_t="";
var porc_dif="";
var txt ="";
var selector = [];
 const addZero=(i)=>{
                    if (i < 10) {
                        i = "0" + i;
                    }
                    return i;
                }
export default {
    name: 'Personal',


    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'AUMENTOS Y PORCENTAJES GUARDADOS',
            descripcion: 'En este modulo usted podra visualizar todas las proyecciones y aumentos guardados',
            personal: [],
        }
    },

    async mounted() {






    const axios1 = axios.get('https://aplicativos.firebaseio.com/liquidacion_recibos/AME.json');
    const axios2 = axios.get('https://aplicativos.firebaseio.com/liquidacion_recibos/CAT.json');
       

    await axios.all([axios1, axios2]).then(axios.spread((america, catering) => {

var liquidaciones = [];


$.each(america.data,function(clave,valor){
    liquidaciones.push({"anio":valor.anio,"liquidacion_america":valor.liquidacion,"mes":valor.mes,"option":valor.option})
    
 });

$.each(catering.data,function(clave,valor){
    liquidaciones.push({"anio":valor.anio,"liquidacion_catering":valor.liquidacion,"mes":valor.mes,"option":valor.option})
    
 });

const mergedData = Object.values(liquidaciones.reduce((acc, curr) => {
  const caption = curr.option.split(" ")[0];
  if (!acc[caption]) {
    acc[caption] = [];
  }
  acc[caption].push(curr);
  return acc;
}, {})).map(group => group.reduce((acc, curr) => Object.assign(acc, curr), {}));


localStorage.setItem("liquidaciones",JSON.stringify(mergedData));


    var count = 0;
    var limite = Object.keys(mergedData).length;
     $.each(mergedData,function(clave,valor){
        count++

        var mes = valor.mes;
        if (mes<10) {
            mes="0"+mes
        }
        
        if (count==limite) {

            $("#liquidacion-a").prepend('<option selected="true" value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            $("#liquidacion-b").prepend('<option selected="true" data-fecha="'+valor.anio+'-'+mes+'-28" value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            selector.push({ [valor.mes+'-'+valor.anio+'-'+valor.liquidacion_america+'-'+valor.liquidacion_catering]:valor.option })

        }else{

            $("#liquidacion-a").prepend('<option value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            $("#liquidacion-b").prepend('<option data-fecha="'+valor.anio+'-'+mes+'-28" value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            selector.push({ [valor.mes+'-'+valor.anio+'-'+valor.liquidacion_america+'-'+valor.liquidacion_catering]:valor.option })

        }
        
     

     });




})).then(() => {


            
$("#loading").hide();
$(".table").hide();
               

        });


    },
     methods: {
        guardar() {

               
                    (async function() {


        const userData = JSON.parse(sessionStorage.getItem('userData'));
        var h = new Date();
        var hora = addZero(h.getHours());
        var minutos = ("0" + h.getMinutes()).substr(-2);
        var segundos = ("0" + h.getSeconds()).substr(-2);
        var horaActual = hora + ":" + minutos + ":" + segundos;

        var hoy = new Date();

        var m = hoy.getMonth() + 1;
        var mes = m < 10 ? "0" + m : m;
        var dia = hoy.getDate();
        dia = dia < 10 ? "0" + dia : dia;
        var fechaActualok = dia + "-" + mes + "-" + hoy.getFullYear();
        var fechaActualok_b = hoy.getFullYear() + "-" + mes + "-" + dia;




            

           

            


                await Swal.fire({
                      title: 'Escribe un nombre para identificar tus aumentos',
                      input: 'text',
                      inputAttributes: {
                        autocapitalize: 'off'
                      },
                      showCancelButton: true,
                      cancelButtonText: 'Cancelar',
                      confirmButtonText: 'Guardar',
                      showLoaderOnConfirm: true,
                      preConfirm: (login) => {
                       
                            firebase.database().ref('costos_publicados/').push({
                              nombre:login,
                              fecha:fechaActualok_b,
                              timestamp:fechaActualok_b+" "+horaActual,
                              creador_dni:userData.leg_numdoc,
                              creador_name:userData.leg_nombre+" "+userData.leg_apellido,
                              liquidacion:localStorage.getItem("costo_liq"),
                              inicio:$("#liquidacion-a option:selected").text()
                              

                            })
                            .then(()=>{
                              
                                 Swal.fire(
                                      "Guardado exitosamente",
                                      "Los datos fueron guardados cotrrectamente, podras visualizarlos en la secciòn Autorizados Guardados",
                                      "success"
                                    );

                            })
                            .catch(e => {
                              console.log(e);
                            });


                      },
                      allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                      if (result.isConfirmed) {
                        console.log(result);
                      }
                    })

            


})();

           
         },

        removeDuplicates(originalArray, prop) {
             var newArray = [];
             var lookupObject  = {};

             for(var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
             }

             for(i in lookupObject) {
                 newArray.push(lookupObject[i]);
             }
              return newArray;
         },

         calcular(legajo){

             const moneda = (numero) => {
              const formatoMoneda = new Intl.NumberFormat('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 0, // Establecer el número mínimo de dígitos decimales a cero
                maximumFractionDigits: 0, // Establecer el número máximo de dígitos decimales a cero
                currencyDisplay: 'narrowSymbol' // Opción para eliminar el símbolo de moneda
              });

              const numeroFormateado = formatoMoneda.format(numero);

              return numeroFormateado;
            }


                  var per = parseFloat($("#basico_b-"+legajo).val());
                  var basico = parseFloat($("#basico_a-"+legajo).html());
                  var cuenta = (basico*per)/100;
                  var total = parseFloat(basico+cuenta).toFixed(3);





                    if (isNaN(total)||total==0) {
                      total = 0;
                      $("#proyeccion_a-"+legajo).html(0)
                    } else {
                      $("#proyeccion_a-"+legajo).html(total)
                    }


                    //DIFERENCIA

                    var direfencia = total-basico;
                    console.log(direfencia)

                    if (isNaN(direfencia)||direfencia<0) {
                      
                      $("#diferencia-"+legajo).html(0)
                      sessionStorage.setItem("total-"+legajo,0)
                    } else {
                      $("#diferencia-"+legajo).html(moneda(direfencia.toFixed(3).replace(".","")))
                      sessionStorage.setItem("total-"+legajo,direfencia.toFixed(3).replace(".",""))
                    }



   var table = $('#empresas').DataTable();                     
  // Obtener los datos de la columna "País"
  var data = table.column(0).data();

  // Crear un array de valores únicos de la columna "País"
  var uniqueData = data.unique();

  
  var sumar = 0;
  // Agregar las opciones del filtro select con los valores únicos de la columna "País"
  $.each(uniqueData, function(i, val) {


console.log(liquidacion)
    if (sessionStorage.getItem("total-"+val) !== null) {
        sumar+=parseFloat(sessionStorage.getItem("total-"+val));

       

    }
    
        
    
  });

  console.log("SUMA == "+sumar)


const numeroMoneda = localStorage.getItem("costo");
const numeroEntero = parseFloat(numeroMoneda.replace(/\$|\.+/g, ''));
var costo=moneda(numeroEntero+sumar); // output: 112337086
var costo_entero=numeroEntero+sumar;
//$("#total_b").html(costo)                   

 

 var porcentajeAumento = ((costo_entero - numeroEntero) / numeroEntero) * 100;
 var porcentaje = parseFloat(porcentajeAumento.toFixed(2));
 console.log("PORCENTAJE :: "+porcentaje)

 var resta = costo_entero-numeroEntero;

if (porcentaje>0.00) {
      //$("#porc_cos_a").html('<span class="arraw igual badge badge-danger"> %'+porcentaje+' ↑</span>')   
}else{
     //$("#porc_cos_a").html('<span class="arraw igual badge badge-light"> %'+porcentaje+' ↔</span>')   
}

if (costo_entero!=numeroEntero) {
      $("#diferencia_t").html("$"+moneda(resta))
      $("#porc_dif").html('<span class="arraw igual badge badge-danger">↑</span>')   
}else{
     $("#diferencia_t").html("$"+moneda(resta))
     $("#porc_dif").html('<span class="arraw igual badge badge-light">↔</span>')     
}


                


                  
        },

        async comparar(){

            $("#loading").show();

            

            const moneda = (numero) => {
              const formatoMoneda = new Intl.NumberFormat('es-AR', {
                style: 'currency',
                currency: 'ARS',
                minimumFractionDigits: 0, // Establecer el número mínimo de dígitos decimales a cero
                maximumFractionDigits: 0, // Establecer el número máximo de dígitos decimales a cero
                currencyDisplay: 'narrowSymbol' // Opción para eliminar el símbolo de moneda
              });

              const numeroFormateado = formatoMoneda.format(numero);

              return numeroFormateado;
            }


          
        $(".table").show();
        $("#alerta").hide(); 

        $("#guardar").show(); 
         $("#botones").show(); 

        $('#spreadsheet').html("");

         var liq_1_a = $("#liquidacion-a option:selected").val().split("-")[0];
         var liq_1_b = $("#liquidacion-a option:selected").val().split("-")[1];
         
         localStorage.setItem("costo_liq",liq_1_a+'-'+liq_1_b);

         $(".fecha1").html($("#liquidacion-a option:selected").text())
         $(".fecha2").html( $("#liquidacion-b option:selected").text())

        const data = JSON.parse(localStorage.getItem("liquidaciones"));
        const ultimoResultado = data[data.length - 1];
        const liquidacionAmericaUltimoResultado = ultimoResultado["liquidacion_america"];
        const liquidacionCateringUltimoResultado = ultimoResultado["liquidacion_catering"];
        const ultimoMes = ultimoResultado["option"];

        console.log(liquidacionAmericaUltimoResultado)
        console.log(liquidacionCateringUltimoResultado)
         

        const axiosrequest1 = axios.get('https://auth.foodservice.com.ar/?type=personal&access_token=1234567');
        const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/categorias.json?print=pretty');

        
        const axiosrequest3 = axios.get('https://auth.foodservice.com.ar/?type=costos_ame&liquidacion='+liq_1_a+'&access_token=1234567');
        const axiosrequest4 = axios.get('https://auth.foodservice.com.ar/?type=costos_cat&liquidacion='+liq_1_b+'&access_token=1234567');

        const axiosrequest5 = axios.get('https://aplicativos.firebaseio.com/proyecciones/'+liq_1_a+'-'+liq_1_b+'.json');



        const axiosrequest6 = axios.get('https://auth.foodservice.com.ar/?type=costos_ame&liquidacion='+liquidacionAmericaUltimoResultado+'&access_token=1234567');
        const axiosrequest7 = axios.get('https://auth.foodservice.com.ar/?type=costos_cat&liquidacion='+liquidacionCateringUltimoResultado+'&access_token=1234567');

        const axiosrequest8 = axios.get('https://aplicativos.firebaseio.com/legales.json?print=pretty');

        const axiosrequest9 = axios.get('https://aplicativos.firebaseio.com/requisitoria_salarial/'+liq_1_a+'-'+liq_1_b+'.json');

        const axiosrequest10 = axios.get('https://aplicativos.firebaseio.com/ajuste_salarial.json?print=pretty');

        const axiosrequest11 = axios.get('https://aplicativos.firebaseio.com/categorias_cat.json?print=pretty');
       

       await axios.all([axiosrequest1, axiosrequest2, axiosrequest3,axiosrequest4,axiosrequest5,axiosrequest6,axiosrequest7,axiosrequest8,axiosrequest9,axiosrequest10,axiosrequest11]).then(axios.spread((personal, categorias, costo_a,costo_b,proyeccion,costos_pa,costos_pb,legales,ajustes,ajuste_requi,categorias_cat) => {

            
           
            const costox_a = Object.assign({}, costo_a.data, costo_b.data);
            
            const costox_b = Object.assign({}, costos_pa.data, costos_pb.data);
            
            
            $("#personal_a").html(Object.keys(costox_a).length);
            $("#personal_b").html(Object.keys(costox_b).length);

            var c_a = 0;
            $.each(costox_a, function(clave, value) {
                
                if (value.datos.basico==null) {
                    c_a += 0;
                }else{
                    c_a += parseFloat(value.datos.basico);
                }
                


            })

             var num_a = c_a.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
                 num_a = num_a.split('').reverse().join('').replace(/^[.]/,'');
            $("#total_a").html("$"+num_a);


             var c_b = 0;
            $.each(costox_a, function(clave, value) {
                c_b += parseFloat(value.costo_laboral.monto.toFixed(2));


            })

         

            //$("#total_b").html("$"+num_a);
            localStorage.setItem("costo",num_a)

            var resultado = c_a-c_a;

             var result = resultado.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
                 result = result.split('').reverse().join('').replace(/^[.]/,'');

            $("#diferencia_t").html("$"+result);




            var pa = parseFloat($("#personal_a").text());
            var pb = parseFloat($("#personal_a").text());
            var porc_a = (pb-pa)*100/pa;

            var porcx_a = "";

            if (porc_a>0) {
                porcx_a = '<span class="arraw up badge badge-danger"> %'+Math.round(porc_a)+' ↑</span>';
            }
            if (porc_a<0) {
                porcx_a = '<span class="arraw down badge badge-success"> %'+Math.round(porc_a)+' ↓</span>';
            }
            if (porc_a==0) {
                porcx_a = '<span class="arraw igual badge badge-light"> %'+Math.round(porc_a)+' ↔</span>';
            }

            $("#porc_per_a").html(porcx_a);



            var pca = parseFloat($("#total_a").text().replace("$",""));
            var pcb = parseFloat($("#total_a").text().replace("$",""));
            var porc_b = (pcb-pca)*100/pca;

            var porcx_b = "";

            if (porc_b>0) {
                porcx_b = '<span class="arraw up badge badge-danger"> %'+Math.round(porc_b)+' ↑</span>';
            }
            if (porc_b<0) {
                porcx_b = '<span class="arraw down badge badge-success"> %'+Math.round(porc_b)+' ↓</span>';
            }
            if (porc_b==0) {
                porcx_b = '<span class="arraw igual badge badge-light"> %'+Math.round(porc_b)+' ↔</span>';
            }

            $("#porc_cos_a").html(porcx_b);


            var dif_a = parseFloat($("#total_a").text().replace("$",""));
           

            var difx_b = "";

            if (dif_a>0) {
                difx_b = '<span class="arraw up badge badge-danger"> ↑ </span>';
            }
            if (dif_a<0) {
                difx_b = '<span class="arraw down badge badge-success"> ↓ </span>';
            }
            if (dif_a==0) {
                difx_b = '<span class="arraw igual badge badge-light"> ↔ </span>';
            }

            $("#porc_dif").html('<span class="arraw igual badge badge-light"> ↔ </span>');


            const convencionado = (id) => {
                var convenio = "";
                $.each(personal.data, function(clave, value) {

                    if (value.leg_numero == id) {
                        convenio = value.leg_convencionado;
                    }
                })
                return convenio;
            }


            const result_categoria = (id) => {
                var categoria = "";
                $.each(categorias.data, function(clave, value) {

                    if (value.codigo == id) {
                        categoria = value.nombre;
                    }
                })
                return categoria;
            }




            const result_categoria_cat = (id) => {
                var categoria = "";
                $.each(categorias_cat.data, function(clave, value) {

                    if (value.codigo == id) {
                        categoria = value.nombre;
                    }
                })
                return categoria;
            }



         
           


            

            const result_remunerativo_a = (id) => {
                var remunerativo = "";
                $.each(costox_a, function(clave, value) {

                    if (clave == id) {
                        remunerativo = parseFloat(value.remunerativo.monto).toFixed(0);
                    }
                })
                return remunerativo;
            }

            const result_noremunerativo_a = (id) => {
                var noremunerativo = "";
                $.each(costox_a, function(clave, value) {

                    if (clave == id) {

                        if (value.no_remunerativo!=null) {

                        noremunerativo = parseFloat(value.no_remunerativo.monto).toFixed(0);

                        }
                    }
                })
                return noremunerativo;
            }

            const result_total_a = (id) => {
                var costo_laboral = "";
                $.each(costox_a, function(clave, value) {

                    if (clave == id) {

                        if (value.costo_laboral!=null) {

                        costo_laboral = parseFloat(value.costo_laboral.monto).toFixed(0);

                        }
                    }
                })
                return costo_laboral;
            }

            

            const result_remunerativo_b = (id) => {
                var remunerativo = "";
                $.each(costox_b, function(clave, value) {

                    if (clave == id) {
                        remunerativo = parseFloat(value.remunerativo.monto).toFixed(0);
                    }
                })
                return remunerativo;
            }

            const result_noremunerativo_b = (id) => {
                var noremunerativo = "";
                $.each(costox_b, function(clave, value) {

                    if (clave == id) {

                        if (value.no_remunerativo!=null) {

                        noremunerativo = parseFloat(value.no_remunerativo.monto).toFixed(0);

                        }
                    }
                })
                return noremunerativo;
            }

            const result_total_b = (id) => {
                var costo_laboral = "";
                $.each(costox_b, function(clave, value) {

                    if (clave == id) {

                        if (value.costo_laboral!=null) {

                        costo_laboral = value.costo_laboral.monto.toFixed(0);

                        }
                    }
                })
                return costo_laboral;
            }



            const basico_a = (id) => {
                var basico = "";
                $.each(costox_a, function(clave, value) {

                    if (clave == id) {

                        if (value['datos'].basico==null) {
                              
                               basico = parseFloat(value['remunerativo'].monto).toFixed(0);
                        }else{
                            if (value['datos'].basico==null) {
                                basico=0;
                            }else{
                                basico = parseFloat(value['datos'].basico).toFixed(0);
                            }
                        }
                        
                    }
                })
                return basico;
            }





            const basico_b = (id) => {
                var basico = "";
                $.each(costox_b, function(clave, value) {

                   if (clave == id) {
                        if (value['datos'].basico==null) {
                            basico=0;
                        }else{
                            basico = parseFloat(value['datos'].basico).toFixed(0);
                        }
                        
                    }
                })
                return basico;
            }







const proyeccion_x = (id)=>{


/* CAPTURAR LOS LEGAJOS E IMPRIMIR LOS GUARDADOS */

var p_x=0;

        
       

                $.each(datos_guardados,function(clave,valor){

                    $.each(valor,function(key,val){
                        var label = key.split("-");
                        
                            if (label[1]==id) {
                                if (label[0]=="proyeccion") {
                                    p_x=val
                                }
                            }

                            /*if (label[0]=="totalx") {
                                $("#diferencia-"+label[1]).html(val)
                            }

                            if (label[0]=="porcentaje") {
                                $("#basico_b-"+label[1]).val(val)
                            }*/
                            
                           
                            
                        
                    });
                });
            
                   console.log(p_x)
                return p_x;
        

/* CIERRA CAPTURAR LOS LEGAJOS E IMPRIMIR LOS GUARDADOS */




}


const total_x = (id)=>{


/* CAPTURAR LOS LEGAJOS E IMPRIMIR LOS GUARDADOS */

                var t_x=0;

        
       

                $.each(datos_guardados,function(clave,valor){

                    $.each(valor,function(key,val){
                        var label = key.split("-");
                        
                            if (label[1]==id) {
                                if (label[0]=="totalx") {
                                    t_x=val
                                }
                            }

                            /*
                            if (label[0]=="porcentaje") {
                                $("#basico_b-"+label[1]).val(val)
                            }*/
                            
                           
                            
                        
                    });
                });
            
                   
                return t_x;
        

/* CIERRA CAPTURAR LOS LEGAJOS E IMPRIMIR LOS GUARDADOS */




}



const porcentaje_x = (id)=>{


/* CAPTURAR LOS LEGAJOS E IMPRIMIR LOS GUARDADOS */

var p_x=0;

        
       

                $.each(datos_guardados,function(clave,valor){

                    $.each(valor,function(key,val){
                        var label = key.split("-");
                        
                            if (label[1]==id) {
                                if (label[0]=="porcentaje") {
                                    p_x=val
                                }
                            }

                            /*
                            if (label[0]=="porcentaje") {
                                $("#basico_b-"+label[1]).val(val)
                            }*/
                            
                           
                            
                        
                    });
                });
            
                   
                return p_x;
        

/* CIERRA CAPTURAR LOS LEGAJOS E IMPRIMIR LOS GUARDADOS */




}



            const egreso = (id) => {
                var egreso = "";
                $.each(personal.data, function(clave, value) {

                    if (value.leg_numero == id) {
                        
                        egreso = value.leg_fecegr;
                        
                    
                    }
                })
                return egreso;
            }


             const getDni = (id) => {
                var docu = "";
                $.each(personal.data, function(clave, value) {

                    if (value.leg_numero == id) {
                        
                        docu = value.leg_numdoc;
                        
                    
                    }
                })
                return docu;
            }



            const legal = (id) => {
                var legale = false;
                $.each(legales.data, function(clave, value) {

                    if (clave == getDni(id)) {
                        
                        legale = true;
                        
                    
                    }
                })
                return legale;
            }


const edito = (id,columna,datos) => {
    var liqui=liq_1_a+'-'+liq_1_b; 
    var status = [];
    var count=0;
    try {
        $.each(proyeccion, function (key, valor) {
            $.each(valor, function (clave, value) {
                if (parseFloat(value.legajo) === parseFloat(id)) {

                    if (parseFloat(value.columna) === parseFloat(columna)) {
                        count++;

                        status.push({
                            "editor": value.editor,
                            "legajo": value.legajo,
                            "nuevoValor": value.nuevoValor,
                            "fecha": value.fecha,
                            "porcentaje": value.porcentaje,
                            "clave":count,
                            "audita":'<span style="background-color: #073f00;padding: 4px;color: #fff;font-size: 8px;font-weight: bold;">'+value.editor+' </span> <span style="font-size: 10px;color:black;">'+value.fecha+'</span><a href="#" data-liquidacion='+liqui+'>+</a>'
                        });

                    }
                }
            });
        });
        
        
        
        
    } catch (error) {

        if (status.length==0) {
            count++;
             status.push({
                            "editor": '',
                            "legajo": id,
                            "nuevoValor": datos,
                            "fecha": '',
                            "porcentaje": '',
                            "clave":'',
                            "audita":''
                        });
            
        }


         if (status.length>1) {
            return status[status.length-1];
        }else{
            return status[0];
        }
    }
};


const historico = (id) => {
    var liqui=liq_1_a+'-'+liq_1_b; 
    var status = [];
    var count=0;
    try {
        $.each(proyeccion, function (key, valor) {
            $.each(valor, function (clave, value) {
                if (parseFloat(value.legajo) === parseFloat(id)) {

                        count++;

                        status.push({
                            "editor": value.editor,
                            "legajo": value.legajo,
                            "nuevoValor": value.nuevoValor,
                            "fecha": value.fecha,
                            "porcentaje": value.porcentaje,
                            "mes": value.mes,
                            "clave":count,
                            "audita":'<span style="background-color: #073f00;padding: 4px;color: #fff;font-size: 8px;font-weight: bold;">'+value.editor+' </span> <span style="font-size: 10px;color:black;">'+value.fecha+'</span><a href="#" data-liquidacion='+liqui+'>+</a>'
                        });

                    }
                
            });
        });
        
        
         return status;
        
    } catch (error) {

       

        return status;
    }
};



const ajuste_salarial = (id) => {
    var liqui=liq_1_a+'-'+liq_1_b; 
    var status = [];
    var count=0;
    try {
        $.each(ajustes.data, function (key, value) {
           
               
                if (parseFloat(value.legajo) === parseFloat(id)) {

                        count++;

                        status.push({
                            "editor": value.editor,
                            "legajo": value.legajo,
                            "nuevoValor": value.nuevoValor,
                            "fecha": value.fecha,
                            "porcentaje": value.porcentaje,
                            "mes": value.mes,
                            "clave":count,
                            "comentario":value.comentario
                        });

                    }
                
            
        });
        
        
         return status;
        
    } catch (error) {

       

        return status;
    }
};


    const result_ajuste = (legajo,mes,basico) => {
                var ajuste = basico;
                $.each(ajuste_requi.data, function(clave, value) {

                    if (value.legajo == legajo) {

                        if (value.mes_ajuste == mes) {

                            if (value.statusDirectorio == 1) {

                                    ajuste = value.salario_nuevo;

                             }

                        }
                    }
                })
                return ajuste;
            }



 const result_info = (legajo,mes) => {
                var info = 0;
                $.each(ajuste_requi.data, function(clave, value) {

                    if (value.legajo == legajo) {

                        if (value.mes_ajuste == mes) {

                            if (value.statusDirectorio == 1) {

                                    info = parseFloat(value.salario_nuevo);

                             }

                        }
                    }
                })
                return info;
            }



             const result_info_comentario = (legajo,mes,basico) => {
                var info = 0;
                $.each(ajuste_requi.data, function(clave, value) {

                    if (value.legajo == legajo) {

                        if (value.mes_ajuste == mes) {

                            if (value.statusDirectorio == 1) {

                                var valorInicial = parseFloat(basico.replace(/[$.,]/g, ""));
                                var valorFinal = parseFloat(value.salario_nuevo);

                                console.log(valorInicial)
                                console.log(parseFloat(value.salario_nuevo))

                                // Calcula la diferencia entre el valor final y el valor inicial
                                var diferencia = valorFinal - valorInicial;

                                // Calcula el porcentaje necesario para alcanzar la diferencia
                                var porcentaje = (diferencia / valorInicial) * 100;

                                console.log("Debes agregar un " + porcentaje.toFixed(2) + "% al valor inicial para llegar a " + valorFinal+"Motivo: "+value.motivo);


                                    info = "Debes agregar un " + porcentaje.toFixed(2) + "% al valor inicial para llegar a " + valorFinal+"Motivo: "+value.motivo;

                             }

                        }
                    }
                })
                return info;
            }
            
            

            
            var lista = []; 
            var guardado = [];    
             // abre liquidacion 1



// Objeto que mapea nombres de meses en español a inglés
var mesesTraduccion = {
  'Enero': 'January',
  'Febrero': 'February',
  'Marzo': 'March',
  'Abril': 'April',
  'Mayo': 'May',
  'Junio': 'June',
  'Julio': 'July',
  'Agosto': 'August',
  'Septiembre': 'September',
  'Octubre': 'October',
  'Noviembre': 'November',
  'Diciembre': 'December'
};

// Nombre del mes en español
var mesEnEspanol = 'agosto';

// Traducir el nombre del mes al inglés
var mesEnIngles = mesesTraduccion[mesEnEspanol];

console.log(mesEnIngles); // Esto mostrará "August"

var mes_parse = $("#liquidacion-a option:selected").text().split(" ");
var mes_original=mesesTraduccion[mes_parse[0]];
var anio_original=mes_parse[1];
var liqui_data=mes_original+" "+anio_original

// Fecha inicial
    const fechaInicial = new Date(liqui_data);
console.log($("#liquidacion-a option:selected").text())
// Nombres de meses abreviados
    const mesesAbreviados = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

    // Crear un array para almacenar las fechas
    const fechasSiguientes = [];
    const fechasSiguientes_b = [];

    // Generar las siguientes 4 fechas
    for (let i = 0; i < 5; i++) {
      const fechaSiguiente = new Date(fechaInicial);
      fechaSiguiente.setMonth(fechaInicial.getMonth() + i);
      const mesAbreviado = mesesAbreviados[fechaSiguiente.getMonth()];
      const año = fechaSiguiente.getFullYear();
      fechasSiguientes.push(`${mesAbreviado} ${año}`);
      fechasSiguientes_b.push(mesAbreviado+' de '+año);
      console.log(fechaSiguiente.getMonth())
    }



            $.each(costox_a, function(clave, valor) {



var basico = result_ajuste(clave,$("#liquidacion-a option:selected").text(),basico_a(clave));



                    
                    var p_total = (basico_b(clave)-basico)*100/basico;

                    if (p_total==Infinity) {
                         p_total = (basico-basico_b(clave))*100/basico_b(clave);
                    }

               
                    var convenio = "FUERA DE CONVENIO";
                    if (convencionado(clave)==1) {
                        convenio = "CCT";
                    }

                    sessionStorage.removeItem("total-"+clave)

                       



var editor = "";


if (edito(clave,3,basico).audita!="") {

   editor=edito(clave,3,basico).audita;

}

if (edito(clave,5,basico).audita!="") {

   editor=edito(clave,5,basico).audita;

}

if (edito(clave,7,basico).audita!="") {

   editor=edito(clave,7,basico).audita;

}

if (edito(clave,9,basico).audita!="") {

   editor=edito(clave,9,basico).audita;

}


var porcentaje_suma = [];
var liqui=liq_1_a+'-'+liq_1_b; 

guardado.push(historico(clave))

var pv3=0;
var pv5=0;
var pv7=0;
var pv9=0;

if (parseFloat(edito(clave,3,basico).porcentaje.replace(/[^0-9.-]/g, ''))>0) {
    
    pv3=parseFloat(edito(clave,3,basico).porcentaje.replace(/[^0-9.-]/g, ''));

}
if (parseFloat(edito(clave,5,basico).porcentaje.replace(/[^0-9.-]/g, ''))>0) {
    pv5=parseFloat(edito(clave,5,basico).porcentaje.replace(/[^0-9.-]/g, ''));
}
if (parseFloat(edito(clave,7,basico).porcentaje.replace(/[^0-9.-]/g, ''))>0) {
    pv7=parseFloat(edito(clave,7,basico).porcentaje.replace(/[^0-9.-]/g, ''));
}
if (parseFloat(edito(clave,9,basico).porcentaje.replace(/[^0-9.-]/g, ''))>0) {
    pv9=parseFloat(edito(clave,9,basico).porcentaje.replace(/[^0-9.-]/g, ''));
}


                        
                        porcentaje_suma=[
                            pv3,
                            pv5+pv3,
                            pv7+pv5+pv3,
                            pv9+pv7+pv5+pv3
                        ];


                        var pvc3=0;
                        var pvc5=0;
                        var pvc7=0;
                        var pvc9=0;

                        if (parseFloat(edito(clave,3,basico).porcentaje.replace(/[^0-9.-]/g, ''))>0) {
                            pvc3=parseFloat(edito(clave,3,basico).porcentaje.replace(/[^0-9.-]/g, ''));
                        }
                        if (parseFloat(edito(clave,5,basico).porcentaje.replace(/[^0-9.-]/g, ''))>0) {
                            pvc5=parseFloat(edito(clave,5,basico).porcentaje.replace(/[^0-9.-]/g, ''));
                        }
                        if (parseFloat(edito(clave,7,basico).porcentaje.replace(/[^0-9.-]/g, ''))>0) {
                            pvc7=parseFloat(edito(clave,7,basico).porcentaje.replace(/[^0-9.-]/g, ''));
                        }
                        if (parseFloat(edito(clave,9,basico).porcentaje.replace(/[^0-9.-]/g, ''))>0) {
                            pvc9=parseFloat(edito(clave,9,basico).porcentaje.replace(/[^0-9.-]/g, ''));
                        }


                        var contable_id="";
                        var contable_desc="";
                        if (localStorage.getItem("ubicacion-"+clave)=="029081515"||localStorage.getItem("ubicacion-"+clave)=="033503144"||localStorage.getItem("ubicacion-"+clave)=="033503155"||localStorage.getItem("ubicacion-"+clave)=="033503166"||localStorage.getItem("ubicacion-"+clave)=="033503177"||localStorage.getItem("ubicacion-"+clave)=="67"||localStorage.getItem("ubicacion-"+clave)=="68"||localStorage.getItem("ubicacion-"+clave)=="69"||localStorage.getItem("ubicacion-"+clave)=="70"||localStorage.getItem("ubicacion-"+clave)=="71"||localStorage.getItem("ubicacion-"+clave)=="75"||localStorage.getItem("ubicacion-"+clave)=="999992"||localStorage.getItem("ubicacion-"+clave)=="999993"||localStorage.getItem("ubicacion-"+clave)=="999999") {
                            contable_id=1;
                            contable_desc="Producción";
                        }else if (localStorage.getItem("ubicacion-"+clave)=="0335029"||localStorage.getItem("ubicacion-"+clave)=="1001") {
                            contable_id=2;
                            contable_desc="Comercialización";
                        }else if (localStorage.getItem("ubicacion-"+clave)=="000558"||localStorage.getItem("ubicacion-"+clave)=="000553"||localStorage.getItem("ubicacion-"+clave)=="000555"||localStorage.getItem("ubicacion-"+clave)=="001016"||localStorage.getItem("ubicacion-"+clave)=="010101999"||localStorage.getItem("ubicacion-"+clave)=="0335030"||localStorage.getItem("ubicacion-"+clave)=="0335031"||localStorage.getItem("ubicacion-"+clave)=="033503188"||localStorage.getItem("ubicacion-"+clave)=="033503199"||localStorage.getItem("ubicacion-"+clave)=="033503200"||localStorage.getItem("ubicacion-"+clave)=="033503201"||localStorage.getItem("ubicacion-"+clave)=="095447887"||localStorage.getItem("ubicacion-"+clave)=="100111928992"||localStorage.getItem("ubicacion-"+clave)=="1002"||localStorage.getItem("ubicacion-"+clave)=="1003"||localStorage.getItem("ubicacion-"+clave)=="1004"||localStorage.getItem("ubicacion-"+clave)=="1005"||localStorage.getItem("ubicacion-"+clave)=="1006"||localStorage.getItem("ubicacion-"+clave)=="1007"||localStorage.getItem("ubicacion-"+clave)=="1008"||localStorage.getItem("ubicacion-"+clave)=="1009"||localStorage.getItem("ubicacion-"+clave)=="1010"||localStorage.getItem("ubicacion-"+clave)=="1011"||localStorage.getItem("ubicacion-"+clave)=="1012"||localStorage.getItem("ubicacion-"+clave)=="1014"||localStorage.getItem("ubicacion-"+clave)=="67"||localStorage.getItem("ubicacion-"+clave)=="70"||localStorage.getItem("ubicacion-"+clave)=="71"||localStorage.getItem("ubicacion-"+clave)=="75"||localStorage.getItem("ubicacion-"+clave)=="100112"||localStorage.getItem("ubicacion-"+clave)=="000559"||localStorage.getItem("ubicacion-"+clave)=="000544"||localStorage.getItem("ubicacion-"+clave)=="000554"||localStorage.getItem("ubicacion-"+clave)=="000564"||localStorage.getItem("ubicacion-"+clave)=="100111"||localStorage.getItem("ubicacion-"+clave)=="100111928992") {
                            contable_id=4;
                            contable_desc="Administración";
                        }else{
                            contable_id=3;
                            contable_desc="Operación";
                        }


                        

                        if (convencionado(clave)==0) {

                            if(egreso(clave)==undefined){

                                if(legal(clave)==false){

var comentario = "";

if (ajuste_salarial(clave).length>0) {
    comentario = "hola";
}

// result_ajuste(legajo,mes)
//$("#liquidacion-a option:selected").text()







                                    var catego=""

                                    if (clave>1000) {

                                         catego = result_categoria(valor.datos.cat_tipo)+" - "+valor.datos.cat_tipo;
                                    }else{
                                        catego = result_categoria_cat(valor.datos.cat_tipo)+" - "+valor.datos.cat_tipo;
                                    }



                                    lista.push([
                                        '<div class="categoria-'+valor.datos.cat_tipo+'">'+clave+'</div>',
                                        valor.datos.nombre.split(', ')[0]+" "+valor.datos.nombre.split(', ')[1]+'<br><span style="background-color: transparent;padding: 4px;color: #000;font-size: 8px;">'+catego+'</span><br><span style="background-color: transparent;padding: 4px;color: #000;font-size: 8px;margin-left:0px;">'+localStorage.getItem("operacion-"+localStorage.getItem("ubicacion-"+clave))+'<br>'+contable_desc+'</span>',
                                        basico,
                                        pvc3,
                                        parseFloat(basico)+(parseFloat(basico)*porcentaje_suma[0])/100,
                                        pvc5,
                                        parseFloat(basico)+(parseFloat(basico)*porcentaje_suma[1])/100,
                                        pvc7,
                                        parseFloat(basico)+(parseFloat(basico)*porcentaje_suma[2])/100,
                                        pvc9,
                                        parseFloat(basico)+(parseFloat(basico)*porcentaje_suma[3])/100,

                                       editor
                                       ]
                                    )
                                }
                            }
                        }


                    
                    

 

                       
$("#mas-"+liqui+"-"+clave).click(function(){
    console.log("data click")
})
                


            });

            // cierra liquidacion 1

          
           localStorage.setItem("editados",JSON.stringify(guardado))


         



//////////////////////////////////////////////


const formatCurrency = (amount) => {
      return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }  

var data = lista;

console.log(lista.length)
$("#loading").hide();





var table =jspreadsheet(document.getElementById('spreadsheet'), {
   
    data:data,
    search: true,
    pagination: 10,
    setEditable:false,
    columnSorting:false,
   
    
    paginationOptions: [10,25,50],
    filters: false,
     allowComments:true,
     tabs:[
    {
        sheetName: 'Countries',
        minDimensions:[10,10]
    },
    {
        sheetName: 'Cities',
        minDimensions:[10,10]
    }
],
    
     footers: [[
        'Total',
        '',
        '=ROUND(SUM(C1:C'+lista.length+'),0)',
        '',
        '=ROUND(SUM(E1:E'+lista.length+'),0)',
        '',
        '=ROUND(SUM(G1:G'+lista.length+'),0)',
        '',
        '=ROUND(SUM(I1:I'+lista.length+'),0)',
        '',
        '=ROUND(SUM(K1:K'+lista.length+'),0)',
        ''
        ]],
      onload: function(el, value, column, record, cell, rowIndex) {

const calcularDiferenciaMeses=(fecha1, fecha2) => {
 // Definimos un objeto que mapea nombres de meses a números
  const meses = {
    Enero: 0, Febrero: 1, Marzo: 2, Abril: 3, Mayo: 4, Junio: 5,
    Julio: 6, Agosto: 7, Septiembre: 8, Octubre: 9, Noviembre: 10, Diciembre: 11
  };

  const meses_b = {
    enero: 0, febrero: 1, marzo: 2, abril: 3, mayo: 4, junio: 5,
    julio: 6, agosto: 7, septiembre: 8, octubre: 9, noviembre: 10, diciembre: 11
  };

  // Dividimos las cadenas en mes y año
  const [mes1, año1] = fecha1.split(' ');
  const [mes2, año2] = fecha2.split(' de ');

  // Creamos objetos de fecha con el mes y año
  const fechaInicio = new Date(año1, meses[mes1]);
  const fechaFin = new Date(año2, meses_b[mes2]);

  // Calculamos la diferencia en meses
  const diferenciaEnMilisegundos = fechaFin - fechaInicio;

  // Convertimos la diferencia en meses
  const mesesDeDiferencia = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24 * 30.44); // Promedio de días por mes

  return Math.round(mesesDeDiferencia);
}






 setTimeout(function(){

         for (var i = 0; i < value.records.length; i++) {

            for (var e = 1; e < fechasSiguientes_b.length; e++) {

               
               

               
               

                var informacion_3 = '';
                if (result_info($(value.records[i][0]).text(),fechasSiguientes_b[e])!=0) {
                   
                    var mes_inicio=$("#liquidacion-a option:selected").text();
                    var mes_final=fechasSiguientes_b[e];
                    var diferencia = calcularDiferenciaMeses(mes_inicio, mes_final);
                    var med_id=0;
                    var letra="";

                    if (diferencia==0) {
                        med_id=2;
                        letra="C";
                    }

                    if (diferencia==1) {
                        med_id=4;
                        letra="E";
                    }

                    if (diferencia==2) {
                        med_id=6;
                        letra="G";
                    }
                    if (diferencia==3) {
                        med_id=8;
                        letra="I";
                    }
                    if (diferencia==4) {
                        med_id=10;
                        letra="K";
                    }


                    informacion_3 = '<span class="globo">!</span>';
                     console.log("diferencia de meses: "+diferencia)
                     console.log(fechasSiguientes_b[e])
                     console.log($(value.records[i][0]).text())
                     console.log(result_info($(value.records[i][0]).text(),fechasSiguientes_b[e]))
                     console.log(value.records[i][med_id].dataset.x)
                     console.log(value.records[i][med_id].dataset.y)
                     var numero = parseFloat(value.records[i][med_id].dataset.y)+1;
                     var ubicacion=letra+numero.toString();
                     console.log("REQUISITORIA")
                     console.log($(value.records[i][0]).text())
                     var comentario =result_info_comentario($(value.records[i][0]).text(),fechasSiguientes_b[e],$(value.records[i][2]).text());
                       
                      
                         console.log(ubicacion)
                        table.setComments(ubicacion, comentario,"comentario");
                      
                     console.log("----------------------------------------------------")

                }
            }

           
         }

          },3000)

        
      },

     onbeforechange: function(el, value, column, record, cell, rowIndex) {
           


//console.log($(table.records[0][2]).html().replace(/[^0-9.-]/g, ''))

        console.log(cell)

            setTimeout(function(){


var columna4 = 0;
var columna6 = 0;
var columna8 = 0;
var columna10 = 0;

    for (var i = 0; i < table.records.length; i++) {
       
        columna4+=parseFloat($(table.records[i][4]).html().replace(/[^0-9.-]/g, ''));
        columna6+=parseFloat($(table.records[i][6]).html().replace(/[^0-9.-]/g, ''));
        columna8+=parseFloat($(table.records[i][8]).html().replace(/[^0-9.-]/g, ''));
        columna10+=parseFloat($(table.records[i][10]).html().replace(/[^0-9.-]/g, ''));
        

       






    }

                console.log("CAMBIADO")
                console.log(columna4)


                var footer_a=$(".jexcel > tfoot > tr > td:nth-child(6)").html(formatCurrency(columna4));
                var footer_b=$(".jexcel > tfoot > tr > td:nth-child(8)").html(formatCurrency(columna6));
                var footer_c=$(".jexcel > tfoot > tr > td:nth-child(10)").html(formatCurrency(columna8));
                var footer_d=$(".jexcel > tfoot > tr > td:nth-child(12)").html(formatCurrency(columna10));
                

                /*$(".jexcel > tfoot > tr > td:nth-child(4)").html('=ROUND(SUM(C1:C'+lista.length+'),0)') 
                $(".jexcel > tfoot > tr > td:nth-child(6)").html(formatCurrency(footer_d)) 
                $(".jexcel > tfoot > tr > td:nth-child(8)").html(formatCurrency(footer_e)) */


                var footer_x=$(".jexcel > tfoot > tr > td:nth-child(4)").html();
                $(".jexcel > tfoot > tr > td:nth-child(4)").html(formatCurrency(footer_x)) 




                var liquidacion = localStorage.getItem("liquidacion")
                var editor = localStorage.getItem("editor")
                var nuevoValor = localStorage.getItem("nuevoValor")
                var porcentaje = localStorage.getItem("porcentaje")
                var legajo =localStorage.getItem("legajo")
                var columna =localStorage.getItem("columna")
                var celda =localStorage.getItem("celda")

               

                var fechaHoraActual = new Date();

                var dia = fechaHoraActual.getDate().toString().padStart(2, '0');
                var mes = (fechaHoraActual.getMonth() + 1).toString().padStart(2, '0'); // Los meses en JavaScript son base 0, por eso se suma 1
                var anio = fechaHoraActual.getFullYear();

                var hora = fechaHoraActual.getHours().toString().padStart(2, '0');
                var minutos = fechaHoraActual.getMinutes().toString().padStart(2, '0');
                var segundos = fechaHoraActual.getSeconds().toString().padStart(2, '0');

                var fechaHoraFormateada = anio + '-' + mes + '-' + dia + ' ' + hora + ':' + minutos + ':' + segundos;

                var index = "";

                if (columna==3) {
                    index = 0;
                }
                if (columna==5) {
                    index = 1;
                }
                if (columna==7) {
                    index = 2;
                }
                if (columna==9) {
                    index = 3;
                }

                firedata.database().ref('proyecciones/' + liquidacion +'').push({
                  liquidacion:liquidacion,
                  editor:editor,
                  nuevoValor:nuevoValor,
                  porcentaje:porcentaje,
                  legajo:legajo,
                  fecha:fechaHoraFormateada,
                  columna:columna,
                  celda:celda,
                  mes:fechasSiguientes[index]

                })
                .then(()=>{
                   console.log("datos guardados")
                })
                .catch(e => {
                  console.log(e);
                });


                   /* Highcharts.chart('container', {
                        chart: {
                            type: 'bar'
                        },
                        title: {
                            text: 'Comparativas de costos'
                        },
                        xAxis: {
                            categories: [$("#liquidacion-a option:selected").text(), ultimoMes, 'Proyectado'],
                            labels: {
                            formatter: function() {
                                return this.value.toLocaleString("es-ES");
                            }
                        }
                        },
                        yAxis: {
                            title: {
                                text: 'Liquidaciones'
                            }
                        },
                        series: [{
                            name: 'Meses',
                            data: [parseFloat(footer_c.replace(/[^0-9.-]/g, ''), 10), parseFloat(footer_b.replace(/[^0-9.-]/g, ''), 10), parseFloat(footer_a.replace(/[^0-9.-]/g, ''), 10)]
                        }]
                    });*/





            },1000)
            
           
    },
    onselection: function(instance, x1, y1, x2, y2, origin) {
    var celda = jspreadsheet.getColumnNameFromId([x1, y1]);
    console.log(x1 )
    if(x1==11){
        var data = table.getSelectedRows();
        var regex = /\+/;
        console.log(data)
        var status = regex.test(data[0].childNodes[12].innerText);
        var id = parseFloat(data[0].childNodes[1].innerText);
        if(status){
            var json = [];
            Swal.fire({
              icon: 'success',
              title: 'Historial de ediciòn',
              html: '<div id="historico"></div>',
              footer: 'Los datos presentados son registros unicos guardados al editar una celda si quiere visualizar la actual ediciòn deberà actualizar el sitio'
            })
            $.each(JSON.parse(localStorage.getItem("editados")),function(clave,valor){

                $.each(valor,function(k,v){
                    console.log(v.legajo+" ::: "+id)
                   if(v.legajo==id){
                       $("#historico").append(v.editor+"<br>"+v.fecha+"<br>Mes/Año:"+v.mes+"<br>Porcentaje:"+v.porcentaje+"<br>Nuevo Valor:$"+v.nuevoValor.toLocaleString("es-ES")+"<hr>")
                   }    


                })


            })


            
        }
    }


     
    
},




    columns: [
        {
            type: 'html',
            title:'Legajo',
            width:50,
            align:"left",
            readOnly:true
        },
        {
            type: 'html',
            title:'Nombre y Apellido',
            width:240,
            align:"left",
            readOnly:true,
        },


        {
            type: 'html',
            title:$("#liquidacion-a option:selected").text(),
            mask:'$ #.##',
            width:100,
            decimal:',',
            align:"left",
            readOnly:true
        }

        /* MES */

        ,{
            type: 'numeric',
            title:fechasSiguientes[1],
            mask:'% #.##',
            width:100,
            decimal:'.',
            align:"left",
            readOnly:false
        },


        {
            type: 'numeric',
            title:"Resultado",
            mask:'$ #.##',
            width:100,
            decimal:',',
            align:"left",
            readOnly:true
        }




       /* MES */

        ,{
            type: 'numeric',
            title:fechasSiguientes[2],
            mask:'% #.##',
            width:100,
            decimal:'.',
            align:"left",
            readOnly:false
        },


        {
            type: 'numeric',
            title:"Resultado",
            mask:'$ #.##',
            width:100,
            decimal:',',
            align:"left",
            readOnly:true
        }

       /* MES */

        ,{
            type: 'numeric',
            title:fechasSiguientes[3],
            mask:'% #.##',
            width:100,
             decimal:'.',
            align:"left",
            readOnly:false
        },


        {
            type: 'numeric',
            title:"Resultado",
            mask:'$ #.##',
            width:100,
            decimal:',',
            align:"left",
            readOnly:true
        }

        /* MES */

        ,{
            type: 'numeric',
            title:fechasSiguientes[4],
            mask:'% #.##',
            width:100,
             decimal:'.',
            align:"left",
            readOnly:false
        },


        {
            type: 'numeric',
            title:"Resultado",
            mask:'$ #.##',
            width:100,
            decimal:',',
            align:"left",
            readOnly:true
        }









        ,{
            type: 'html',
            title:'Editado',
            width:250,
            align:"left",
            readOnly:true
        }
     ]
   
});






setTimeout(function(){

    console.log("ahora si")




    $(".filtro").click(function(){

    var filtro = $(this).data("filtro");
    


    table.search(filtro);


    });

},4000)





///////////////////////////////////////////

var footer_a=$(".jexcel > tfoot > tr > td:nth-child(4)").html();
var footer_b=$(".jexcel > tfoot > tr > td:nth-child(6)").html();
var footer_c=$(".jexcel > tfoot > tr > td:nth-child(8)").html();
var footer_d=$(".jexcel > tfoot > tr > td:nth-child(10)").html();
var footer_e=$(".jexcel > tfoot > tr > td:nth-child(12)").html();



$(".jexcel > tfoot > tr > td:nth-child(4)").html(formatCurrency(footer_a))   
$(".jexcel > tfoot > tr > td:nth-child(6)").html(formatCurrency(footer_b))
$(".jexcel > tfoot > tr > td:nth-child(8)").html(formatCurrency(footer_c)) 
$(".jexcel > tfoot > tr > td:nth-child(10)").html(formatCurrency(footer_d)) 
$(".jexcel > tfoot > tr > td:nth-child(12)").html(formatCurrency(footer_e)) 
          
//var unicos = this.removeDuplicates(lista, "legajo");


            this.personal = lista;
            $("#loading").hide();








        })).then(() => {





$("#loading").hide();
 



var footer_a=$(".jexcel > tfoot > tr > td:nth-child(4)").html().replace("$ ","");
var footer_b=$(".jexcel > tfoot > tr > td:nth-child(6)").html().replace("$ ","");
var footer_c=$(".jexcel > tfoot > tr > td:nth-child(8)").html().replace("$ ","");
var footer_d=$(".jexcel > tfoot > tr > td:nth-child(10)").html().replace("$ ","");
var footer_e=$(".jexcel > tfoot > tr > td:nth-child(12)").html().replace("$ ","");

   


      /* Highcharts.chart('container', {
            chart: {
                type: 'bar'
            },
            title: {
                text: 'Comparativas de costos'
            },
            xAxis: {
                categories: [$("#liquidacion-a option:selected").text(), ultimoMes, 'Proyectado'],
                labels: {
                formatter: function() {
                    return this.value.toLocaleString("es-ES");
                }
            }
            },
            yAxis: {
                title: {
                    text: 'Liquidaciones'
                }
            },
            series: [{
                name: 'Meses',
                data: [parseFloat(footer_c.replace(/[^0-9.-]/g, ''), 10), parseFloat(footer_b.replace(/[^0-9.-]/g, ''), 10), parseFloat(footer_a.replace(/[^0-9.-]/g, ''), 10)]
            }]
        });*/
    


         


})

        }

    },



};
</script>

<style scoped>




.load-box {
    background: red;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
}

.up{
    color:green;
}
.down{
    color:red;
}

.colapse{
    position: absolute;
}

#sinreg{
    display: none;
}


.tablesorter tbody > tr > td > div[contenteditable=true]:focus {
  outline: #08f 1px solid;
  background: #eee;
  resize: none;
}
td.no-edit, span.no-edit {
  background-color: rgba(230,191,153,0.5);
}
.focused {
  color: blue;
}
td.editable_updated {
  background-color: green;
  color: white;
}




table {
  position: relative;
  border: 1px solid #ddd;
  border-collapse: collapse;
}

td, th {
  white-space: nowrap;
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
}

th {
  background-color: #eee;
  position: sticky;
  top: -1px;
  z-index: 2;
  
  &:first-of-type {
    left: 0;
    z-index: 3;
  }
}

tbody tr td:first-of-type {
  background-color: #eee;
  position: sticky;
  left: -1px;
  text-align: left;
}

.comentario {
  background-color: #FFD700; /* Cambia el color de fondo */
  color: #333; /* Cambia el color del texto */
  font-style: italic; /* Cambia el estilo de fuente a cursiva */
}







</style>
