<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ breadcrumbB }}</h6>
            <p class="card-description">
              {{ descripcion }}
            </p>

            <h6 class="card-title mt-4">Selecciona las fechas y planta a consultar</h6>
             <div class="row">
                  <div class="col-12">
                    <form>
                        <div class="row mb-3">
                            <div class="col">
                                <label class="mr-sm-2" for="inlineFormCustomSelect">Desde</label>
                                <select id="desdeasist"></select>
                                
                            </div>
                            <div class="col">
                                <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                                <select id="hastaasist"></select>
                            </div>
                           
                        </div>
                       
                        <div class="text-right mt-3">
                            <button @click.prevent="consulta('')" class="btn btn-md btn-success">Consultar evolución salarial</button>
                        </div>
                    </form>
                  </div>
                  
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="errors.fdesdeInvalida">
      <div class="col">
        <div class="alert alert-danger">
          La fecha de inicio es obligatoria, introducila para poder hacer una búsqueda.
        </div>
      </div>
    </div>

    <lista-carga v-if="load"></lista-carga>
    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
              <div class="table-responsive">
                <table id="personal" class="table">
                  <thead>
                    <tr>
                        <th><span class="text-muted">Legajo</span></th>
                        <!-- <th><span class="text-muted">Status</span></th> -->
                        <th><span class="text-muted">Nombre</span></th>
                        <th><span class="text-muted">Categoria</span></th>
                        <th><span class="text-muted">Sector</span></th>
                        <th><span class="text-muted text-wrap">liquidacion 1</span></th>
                        <th><span class="text-muted text-wrap">liquidacion 2</span></th>
                        <th><span class="text-muted text-wrap">% adquirido</span></th>
                        
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="personal in personal" v-bind:key="personal">
                        <td class="text-wrap">{{personal.legajo}}</td>
                        <!-- <td class="text-wrap"><div v-html="value.status"></div></td> -->
                        <td class="text-wrap">
                            <span class="item-amount d-none d-sm-block text-sm text-wrap">
                            {{personal.nombre}}    
                            </span>
                        </td>  
                        <td class="text-wrap">{{personal.cat_tipo}}</td>
                        <td class="text-wrap">{{personal.sector}}</td>
                        <td class="text-wrap">$ {{personal.basico_desde}}</td>
                        <td class="text-wrap">$ {{personal.basico_hasta}}</td>
                        <td class="text-wrap">% {{personal.porcentaje}}</td>
                        
                    </tr>  
                  </tbody>
                </table>
              </div>
            <!-- <div class="flex float-right"></div>
              <button @click="mapa()" class="btn w-sm mb-1 btn-sm btn-primary">
                Ver Mapa en Tiempo real
              </button>
            </div> -->
        </div>
      </div>
    </div>
    
  </div>
  </div>
</template>

<script>

import axios from "axios";
import $ from "jquery";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
import moment from 'moment';
import ListaCarga from '../ListaCarga';

import config from './config'
import DTConfig from '@/dataTableConfig.js'


export default {
  name: "Presentismo",
  components: { ListaCarga },
  data: () => ({
    breadcrumbA: "Inicio",
    breadcrumbB: "Evolución Salarial",
    descripcion: "Podés seleccionar una fecha específica y una planta para visualizar la lista del personal activo.",
    datos: [],
    categorias: [],
    fdesde: null,
    fhasta: null,
    total1: 0,
    conveniono1: 0,
    convenio1: 0,
    zonas: [],
    personal: [],
    asistencias: [],
    load: true,
    zonaSeleccionada:"000001",
    mesActual: null,
    alert:{
      tiempo:'',
    },
    errors: {
      fdesdeInvalida: false,
    }
  }),
  methods: {
    pad: function(n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },
    llena: function(number, len) {
      return "0".repeat(len - number.toString().length) + number.toString();
    },
    obtenerPersonalConCategorias(){
        let vm = this;
        axios
        .get("https://apprrhh-707b9.firebaseio.com/personal.json?print=pretty")
        .then((response) => {
            $.each(response.data, function(i, item) {
                if (item.leg_numdoc > 0){
                    vm.personal[item.leg_numdoc] = item.cat_codigo;
                }
            });    

            axios
            .get("https://aplicativos.firebaseio.com/categorias.json?print=pretty")
            .then((response) => {
                $.each(response.data, function(i, item) {
                    vm.categorias[item.codigo] = item.nombre;
                });
            });
        });
    },
    
    reemplazarIndefinido(string) {
        return (string==undefined || string == null || string == '') ? '-' : string;
    },
    consulta: async function( origin ) {
      

      var desde = $("#desdeasist option:selected").val();
      var hasta = $("#hastaasist option:selected").val();

      // Se activa trigger para mostrar loader placeholder
      this.load = true;

      console.log(`desde: ${desde} hasta: ${hasta}`);

     
      //let url = `https://auth.foodservice.com.ar/?type=presentismo&desde=${this.fdesde}&hasta=${this.fhasta}&access_token=1234567`;
      console.log(desde);
      console.log(hasta)

      var desde_america = desde.split("-")[0];
      var desde_catering = desde.split("-")[1];

      var hasta_america = hasta.split("-")[0];
      var hasta_catering = hasta.split("-")[1];

    const axios1 = axios.get('https://auth.foodservice.com.ar/?type=liq_fs_america&liquidacion='+desde_america+'&access_token=1234567');
    const axios2 = axios.get('https://auth.foodservice.com.ar/?type=liq_fs_catering&liquidacion='+desde_catering+'&access_token=1234567');

    const axios3 = axios.get('https://auth.foodservice.com.ar/?type=liq_fs_america&liquidacion='+hasta_america+'&access_token=1234567');
    const axios4 = axios.get('https://auth.foodservice.com.ar/?type=liq_fs_catering&liquidacion='+hasta_catering+'&access_token=1234567');

    const axios5 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');
    const axios6 = axios.get('https://aplicativos.firebaseio.com/categorias.json?print=pretty');


    const axios7 = axios.get('https://consumos.foodservice.com.ar/api/empresas');
    const axios8 = axios.get('https://aplicativos.firebaseio.com/sectores.json?print=pretty');
    

    await axios.all([axios1, axios2,axios3, axios4, axios5, axios6,axios7,axios8]).then(axios.spread((america_desde, catering_desde,america_hasta, catering_hasta,distribucion,categorias,operaciones,sectores) => {
        // Procesa las respuestas aquí
        const datosAmerica_desde = america_desde.data;
        const datosCatering_desde = catering_desde.data;

        const datosAmerica_hasta = america_hasta.data;
        const datosCatering_hasta = catering_hasta.data;



        const result_categoria = (id) => {
                    var categoria = "";
                    $.each(categorias.data, function(clave, value) {

                        if (value.codigo == id) {
                            categoria = value.nombre;
                        }
                    })
                    return categoria;
                }



                const result_distribucion = (id) => {
                    var distri = "";
                    $.each(distribucion.data, function(clave, value) {
                        $.each(value, function(k, v) {
                            if (v.legajo == id) {
                                distri = {
                                    operacion: clave,
                                    idDistribucion: k
                                }                               
                            }
                        })

                    });
                    return distri;
                }


                const result_operacion = (id) => {
                    var operacion = "";
                  
                    // Busca primero al empleado en sectores que es mas pequeño
                    Object.keys(sectores.data).forEach((sector)=>{
                        // console.log(sectores.data[sector].id_empresa +"=="+id);
                        if (sectores.data[sector].id_empresa == id) {
                            operacion = sectores.data[sector].operacion;
                           

                        }
                    })
                    // Si no encontro un sector asociado busca una empresa asociada
                    if (operacion == "") {
                        $.each(operaciones.data, function(clave, value) {
                            if (value.id == id) {
                                operacion = value.name;
                             
                            }
                        })
                    }
                    return operacion;
                }

       // Asumiendo que ya tienes datosAmerica_desde, datosCatering_desde, datosAmerica_hasta, y datosCatering_hasta definidos

let datosCombinados = [];

// Continuación del código anterior...

for (let legajo in datosAmerica_hasta) {
    let datosHasta = datosAmerica_hasta[legajo].datos;
    let datosDesde = datosAmerica_desde[legajo] ? datosAmerica_desde[legajo].datos : null;

    let basicoDesde = datosDesde ? datosDesde.basico : '-';
    let basicoHasta = datosHasta.basico;

    // Calcular el porcentaje de aumento, si es aplicable
    let aumentoPorcentaje = datosDesde ? ((basicoHasta - basicoDesde) / basicoDesde * 100).toFixed(2) : '-';

    if (isNaN(aumentoPorcentaje)==false) {
        
        if (aumentoPorcentaje != Infinity) {


let distribucion = result_distribucion(parseInt(legajo));
                        
                          


            datosCombinados.push({
                nombre: datosHasta.nombre,
                dni: datosHasta.dni,
                cat_tipo: result_categoria(datosHasta.cat_tipo),
                legajo: parseInt(legajo),
                CBU: datosHasta.CBU,
                Cuil: datosHasta.Cuil,
                basico_desde: basicoDesde,
                basico_hasta: basicoHasta,
                porcentaje: aumentoPorcentaje,
                sector:result_operacion(distribucion.operacion)
            });
        }

    }
}

// Repetir el mismo proceso para datosCatering_hasta
for (let legajo in datosCatering_hasta) {
    let datosHasta = datosCatering_hasta[legajo].datos;
    let datosDesde = datosCatering_desde[legajo] ? datosCatering_desde[legajo].datos : null;

    let basicoDesde = datosDesde ? datosDesde.basico : '-';
    let basicoHasta = datosHasta.basico;

    // Calcular el porcentaje de aumento, si es aplicable
    let aumentoPorcentaje = datosDesde ? ((basicoHasta - basicoDesde) / basicoDesde * 100).toFixed(2) : '-';

   if (isNaN(aumentoPorcentaje)==false) {
        
        if (aumentoPorcentaje != Infinity) {
            let distribucion = result_distribucion(parseInt(legajo));
                        
                          

            datosCombinados.push({
                nombre: datosHasta.nombre,
                dni: datosHasta.dni,
                cat_tipo: result_categoria(datosHasta.cat_tipo),
                legajo: parseInt(legajo),
                CBU: datosHasta.CBU,
                Cuil: datosHasta.Cuil,
                basico_desde: basicoDesde,
                basico_hasta: basicoHasta,
                porcentaje: aumentoPorcentaje,
                sector:result_operacion(distribucion.operacion)
            });
        }

    }
}

console.log(datosCombinados);
this.personal = datosCombinados;


})).then(() => {

console.log("CARGADO")

setTimeout(function(){


var tabla =  $('#personal').DataTable({
                                "language": {
                                    "decimal": ",",
                                    "thousands": ".",
                                    "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                    "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                    "infoPostFix": "",
                                    "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                                    "loadingRecords": "Cargando...",
                                    "lengthMenu": "Mostrar _MENU_ registros",
                                    "paginate": {
                                        "first": "Primero",
                                        "last": "Último",
                                        "next": "Siguiente",
                                        "previous": "Anterior"
                                    },
                                    "processing": "Procesando...",
                                    "search": "Buscar:",
                                    "searchPlaceholder": "Término de búsqueda",
                                    "zeroRecords": "No se encontraron resultados",
                                    "emptyTable": "Ningún dato disponible en esta tabla",
                                    "aria": {
                                        "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                        "sortDescending": ": Activar para ordenar la columna de manera descendente"
                                    },
                                },
                                "scrollX": false, 
                                "search": {regex:true},
                                dom: 'Bfrtip',
                               
                                responsive: false,
                                nowrap: false,
                                buttons: [
                                    'copy',
                                    'excel',
                                    'csv',
                                    'pdf'
                                ]
                            });


},1000)
        // Puedes continuar tu lógica de procesamiento aquí
     }).catch(error => {
        // Manejo de errores
        console.error('Ocurrió un error al realizar las solicitudes:', error);
    });




      this.load = false;




      
    },
  },
 async  mounted() {
    // this.zonaSeleccionada = ;


var selector = [];
 const addZero=(i)=>{
                    if (i < 10) {
                        i = "0" + i;
                    }
                    return i;
                }

 const axios1 = axios.get('https://aplicativos.firebaseio.com/liquidacion_recibos/AME.json');
 const axios2 = axios.get('https://aplicativos.firebaseio.com/liquidacion_recibos/CAT.json');
       

    await axios.all([axios1, axios2]).then(axios.spread((america, catering) => {

var liquidaciones = [];


$.each(america.data,function(clave,valor){
    liquidaciones.push({"anio":valor.anio,"liquidacion_america":valor.liquidacion,"mes":valor.mes,"option":valor.option})
    
 });

$.each(catering.data,function(clave,valor){
    liquidaciones.push({"anio":valor.anio,"liquidacion_catering":valor.liquidacion,"mes":valor.mes,"option":valor.option})
    
 });

const mergedData = Object.values(liquidaciones.reduce((acc, curr) => {
  const caption = curr.option.split(" ")[0];
  if (!acc[caption]) {
    acc[caption] = [];
  }
  acc[caption].push(curr);
  return acc;
}, {})).map(group => group.reduce((acc, curr) => Object.assign(acc, curr), {}));


localStorage.setItem("liquidaciones",JSON.stringify(mergedData));

console.log(mergedData)

mergedData.sort((a, b) => {
    let fechaA = new Date(a.anio, a.mes - 1); // -1 porque los meses en JavaScript van de 0 a 11
    let fechaB = new Date(b.anio, b.mes - 1);

    return fechaA - fechaB; // Ordena de la fecha más cercana a la más antigua
});

    var count = 0;
    var limite = Object.keys(mergedData).length;
     $.each(mergedData,function(clave,valor){
        count++

        var mes = valor.mes;
        if (mes<10) {
            mes="0"+mes
        }
        
        if (count==limite) {

            $("#desdeasist").prepend('<option selected="true" value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            $("#hastaasist").prepend('<option selected="true" data-fecha="'+valor.anio+'-'+mes+'-28" value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            selector.push({ [valor.mes+'-'+valor.anio+'-'+valor.liquidacion_america+'-'+valor.liquidacion_catering]:valor.option })

        }else{

            $("#desdeasist").prepend('<option value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            $("#hastaasist").prepend('<option data-fecha="'+valor.anio+'-'+mes+'-28" value="'+valor.liquidacion_america+'-'+valor.liquidacion_catering+'">'+valor.option+'</option>');
            selector.push({ [valor.mes+'-'+valor.anio+'-'+valor.liquidacion_america+'-'+valor.liquidacion_catering]:valor.option })

        }
        
     

     });




})).then(() => {

this.load = false;
            
$("#loading").hide();
$(".table").hide();
               

        });







  },

};
</script>

<style scoped>
.form-control {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none;
}

.custom-select {
    font-size: .8rem;
}
</style>