<template>
  
 
            <div class="page-content">
                  

                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title">{{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}</p>



            
<div class="row">

    <div class="col-md-4">
                                <select id="liquidacion-a">

                                    <!--
                                     <option value="585">Diciembre 2021</option>
                                     <option value="581">Diciembre SAC 2do Sem. 2021</option>
                                     <option value="580">Noviembre 2021</option>
                                     <option value="578">Octubre 2021</option>
                                     <option value="576">Septiembre 2021</option>
                                     <option value="574">Agosto 2021</option>
                                     <option value="571">Julio 2021</option>


                                    <option value="570">Junio 2021</option>
                                    <option value="565">Mayo 2021</option>
                                    <option value="564">Abril 2021</option>
                                    <option value="558">Marzo 2021</option>
                                    <option value="555">Febrero 2021</option>
                                    <option value="552">Enero 2021</option>


                                    <option value="544">Diciembre 2020</option>
                                    <option value="540">Noviembre 2020</option>
                                    <option value="537">Octubre 2020</option>
                                    <option value="534">Septiembre 2020</option>
                                    <option value="531">Agosto 2020</option>
                                    <option value="526">Julio 2020</option>
                                    <option value="520">Junio 2020</option>
                                    <option value="516">Mayo 2020</option>   
                                    <option value="513">Abril 2020</option>
                                    <option value="510">Marzo 2020</option>
                                    <option value="506">Febrero 2020</option>  
                                    <option value="498">Enero 2020</option>




                                    <option value="495">Diciembre 2019</option>
                                    <option value="491">Noviembre 2019</option>
                                    <option value="487">Octubre 2019</option>
                                    <option value="485">Septiembre 2019</option>
                                    <option value="483">Agosto 2019</option>
                                    <option value="478">Julio 2019</option>
                                    <option value="477">Junio 2019</option>
                                    -->

                                </select>

</div>

    <div class="col-md-4">
                                <select id="liquidacion-b">

                                  <!--
                                        <option data-fecha="2021-12-28" value="585">Diciembre  2021</option>
                                        <option data-fecha="2021-12-28" value="581">Diciembre SAC 2do Sem. 2021</option>
                                        <option data-fecha="2021-11-28" value="580">Noviembre 2021</option>

                                        <option data-fecha="2021-10-28" value="578">Octubre 2021</option>
                                        <option data-fecha="2021-09-28" value="576">Septiembre 2021</option>
                                        <option data-fecha="2021-08-28" value="574">Agosto 2021</option>
                                        <option data-fecha="2021-07-28" value="571">Julio 2021</option>



                                        <option data-fecha="2021-06-28" value="570">Junio 2021</option>
                                        <option data-fecha="2021-05-28" value="565">Mayo 2021</option>
                                        <option data-fecha="2021-04-28" value="564">Abril 2021</option>
                                  


                                        <option data-fecha="2021-03-28" value="558">Marzo 2021</option>
                                        <option data-fecha="2021-02-28" value="555">Febrero 2021</option>
                                        <option data-fecha="2021-01-28" value="552">Enero 2021</option>


                                        <option data-fecha="2020-12-28" value="544">Diciembre 2020</option>
                                        <option data-fecha="2020-11-28" value="540">Noviembre 2020</option>
                                        <option data-fecha="2020-10-28" value="537">Octubre 2020</option>
                                        <option data-fecha="2020-09-28" value="534">Septiembre 2020</option>
                                        <option data-fecha="2020-08-28" value="531">Agosto 2020</option>
                                        <option data-fecha="2020-07-28" value="526">Julio 2020</option>
                                        <option data-fecha="2020-06-28" value="520">Junio 2020</option>
                                        <option data-fecha="2020-05-28" value="516">Mayo 2020</option>   
                                        <option data-fecha="2020-04-28" value="513">Abril 2020</option>
                                        <option data-fecha="2020-03-28" value="510">Marzo 2020</option>
                                        <option data-fecha="2020-02-28" value="506">Febrero 2020</option>  
                                        <option data-fecha="2020-01-28" value="498">Enero 2020</option>




                                        <option data-fecha="2019-12-28" value="495">Diciembre 2019</option>
                                        <option data-fecha="2019-11-28" value="491">Noviembre 2019</option>
                                        <option data-fecha="2019-10-28" value="487">Octubre 2019</option>
                                        <option data-fecha="2019-09-28" value="485">Septiembre 2019</option>
                                        <option data-fecha="2019-08-28" value="483">Agosto 2019</option>
                                        <option data-fecha="2019-07-28" value="478">Julio 2019</option>
                                        <option data-fecha="2019-06-28" value="477">Junio 2019</option>
                                    -->

                                </select>

</div>

 <div class="col-md-4">
     
      <button class="btn btn-primary mr-2 mb-2 mb-md-0 text-white" v-on:click="comparar()">COMPARAR</button>
 </div>

</div>

<br><br>

<table class="table">
 
 <tr>
      <td colspan="2" style="background-color:#d6daad;" class="fecha1">Diciembre 2021</td>
      <td colspan="2" style="background-color:#e2b79d;" class="fecha2">Noviembre 2021</td>
      <td  style="background-color:#ea9460;">Observacion</td>
  </tr>  

  <tr>
      <td style="background-color:#d6daad;">Personal</td>
      <td style="background-color:#d6daad;">Costo total</td>

      <td style="background-color:#e2b79d;">Personal</td>
      <td style="background-color:#e2b79d;">Costo total</td>

       <td style="background-color:#ea9460;">Diferencia</td>
  </tr>  

    <tr>
      <td style="background-color:#d6daad;" id="personal_a">0</td>
      <td style="background-color:#d6daad;" id="total_a">$0</td>

      <td style="background-color:#e2b79d;"><span id="personal_b">0</span>  <span id="porc_per_a"></span></td>
      <td style="background-color:#e2b79d;"><span id="total_b">$0</span> <span id="porc_cos_a"></span></td>

      <td style="background-color:#ea9460;"><span id="diferencia_t">$0</span> <span id="porc_dif"></span></td>
  </tr> 

</table>

                        </div>  
                      </div>
                    </div>
                  </div>    



                  <div class="row">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                
                <div class="table-responsive">
                  
                  

                  <table id="empresas" class="table">
                    <thead>
                       <tr >
                        <td colspan="4" align="center" style="background-color:#d6daad;">Datos generales de nomina</td>
                        <td colspan="4" align="center" style="background-color:#e2b79d;">Costo Liquidacion <span class="fecha1">Diciembre 2021</span></td>
                        <td colspan="4" align="center" style="background-color:#ff9a73;">Costo Liquidacion <span class="fecha2">Noviembre 2021</span></td>
                        <td  colspan="2" align="center" style="background-color:#b7d0b4;">Observación sobre el basico</td>
                        </tr>
                      
                      <tr>
                       
                       
                        <th style="background-color:#d6daad;">Legajo</th>
                        <th style="background-color:#d6daad;">Nombre</th>
                        <th style="background-color:#d6daad;">Apellido</th>
                        <!--<th style="background-color:#d6daad;">Ingreso</th>-->
                        <th style="background-color:#d6daad;">Categoria</th>

                        <th style="background-color:#e2b79d;">Basico</th>
                        <th style="background-color:#e2b79d;">Remun</th>
                        <th style="background-color:#e2b79d;">No Remun</th>
                        <th style="background-color:#e2b79d;">Costo</th>

                        
                        <th style="background-color:#ff9a73;">Basico</th>
                        <th style="background-color:#ff9a73;">Remun</th>
                        <th style="background-color:#ff9a73;">No Remun</th>
                        <th style="background-color:#ff9a73;">Costo</th>

                        <th style="background-color:#b7d0b4;">Diferencia</th>
                        <th style="background-color:#b7d0b4;">%</th>
                        
                       
                        
                      </tr>
                    </thead>
                    <tbody>
                      <tr  v-for="personal in personal"  v-bind:key="personal.legajo">
                        
                        
                        <td class="details-control">{{personal.legajo}}</td>
                        <td>{{personal.nombre}}</td>
                        <td>{{personal.apellido}}</td>
                        <!--<td>{{personal.ingreso}}</td>-->
                        <td>{{personal.categoria}}</td>

                         <td>$ {{personal.basico_a}}</td>
                        <td>$ {{personal.remunerativo_a}}</td>
                        <td>$ {{personal.noremunerativo_a}}</td>
                        <td>$ {{personal.costo_a}}</td>

                        
                         <td>$ {{personal.basico_b}}</td>
                         <td>$ {{personal.remunerativo_b}}</td>
                        <td>$ {{personal.noremunerativo_b}}</td>
                        <td>$ {{personal.costo_b}}</td>

                        <td>$ {{personal.diferencia}}</td>
                        
                        <td>%
                       
                        
                        <span v-if="personal.porcentaje>0"  class="arraw up badge badge-danger" style="color:#fff;"> {{personal.porcentaje}} ↑</span>
                        <span v-if="personal.porcentaje<0" class="arraw down  badge badge-success" style="color:#fff;"> {{personal.porcentaje}} ↓</span>
                        <span v-if="personal.porcentaje==0" class="arraw igual badge badge-light" style="color:#000;"> {{personal.porcentaje}} ↔</span>

                        
                        </td>
                       
                        
                      
                      </tr>
                    


                    </tbody>
                  </table>

<div class="alert alert-warning" role="alert" id="alerta">
  SELECCIONA LAS LIQUIDACIONES A COMPARAR
</div>


                     <div class="col-md-12 grid-margin stretch-card" id="loading">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-title w-25"><div class="load-box"></div></div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row mb-2">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-2">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                        <div class="col-4">
                                            <div class="load-box"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




                </div>
              </div>
            </div>
          </div>
        </div>


            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();



export default {
    name: 'Personal',


    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Costos de Personal Catering',
            descripcion: 'En este modulo usted podra visualizar y comparar el costo del personal de la empresa',
            personal: [],
        }
    },

    async mounted() {

axios.get(' https://aplicativos.firebaseio.com/liquidacion_recibos/CAT.json').then(res => 
{


console.log(res.data)
    var count = 0;
    var limite = Object.keys(res.data).length;
     $.each(res.data,function(clave,valor){
        count++

        var mes = valor.mes;
        if (mes<10) {
            mes="0"+mes
        }
console.log(count+" == "+limite)
if (count==limite) {

    $("#liquidacion-a").prepend('<option selected="true" value="'+valor.liquidacion+'">'+valor.option+'</option>');
    $("#liquidacion-b").prepend('<option selected="true" data-fecha="'+valor.anio+'-'+mes+'-28" value="'+valor.liquidacion+'">'+valor.option+'</option>');

}else{

    $("#liquidacion-a").prepend('<option value="'+valor.liquidacion+'">'+valor.option+'</option>');
    $("#liquidacion-b").prepend('<option data-fecha="'+valor.anio+'-'+mes+'-28" value="'+valor.liquidacion+'">'+valor.option+'</option>');

}
        
     

     });




}); 




$("#loading").hide();
$(".table").hide();


    },
     methods: {

        removeDuplicates(originalArray, prop) {
             var newArray = [];
             var lookupObject  = {};

             for(var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
             }

             for(i in lookupObject) {
                 newArray.push(lookupObject[i]);
             }
              return newArray;
         },

        async comparar(){

            $("#loading").show();

            

            const moneda = (numero) => {

                var nu = numero.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
                return nu = nu.split('').reverse().join('').replace(/^[.]/,'');
            
            }


          
$(".table").show();
$("#alerta").hide(); 

        $('#empresas').DataTable().destroy();

         var liq_1 = $("#liquidacion-a option:selected").val();
         var liq_2 = $("#liquidacion-b option:selected").val();

         $(".fecha1").html($("#liquidacion-a option:selected").text())
         $(".fecha2").html( $("#liquidacion-b option:selected").text())

         console.log(liq_1)

        const axiosrequest1 = axios.get('https://apprrhh-707b9.firebaseio.com/personal_cat.json?print=pretty');
        const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/categorias.json?print=pretty');
        const axiosrequest3 = axios.get('https://auth.foodservice.com.ar/?type=costos_cat&liquidacion='+liq_1+'&access_token=1234567');
        const axiosrequest4 = axios.get('https://auth.foodservice.com.ar/?type=costos_cat&liquidacion='+liq_2+'&access_token=1234567');

       await axios.all([axiosrequest1, axiosrequest2, axiosrequest3,axiosrequest4]).then(axios.spread((personal, categorias, costo_a,costo_b) => {

            var lista = [];
           
            
            
            $("#personal_a").html(Object.keys(costo_a.data).length);
            $("#personal_b").html(Object.keys(costo_b.data).length);

            var c_a = 0;
            $.each(costo_a.data, function(clave, value) {
                c_a += parseInt(value.costo_laboral.monto.toFixed(2));


            })

             var num_a = c_a.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
                 num_a = num_a.split('').reverse().join('').replace(/^[.]/,'');
            $("#total_a").html("$"+num_a);


             var c_b = 0;
            $.each(costo_b.data, function(clave, value) {
                c_b += parseInt(value.costo_laboral.monto.toFixed(2));


            })

            var num_b = c_b.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
                num_b = num_b.split('').reverse().join('').replace(/^[.]/,'');

            $("#total_b").html("$"+num_b);

            var resultado = c_b-c_a;

             var result = resultado.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
                 result = result.split('').reverse().join('').replace(/^[.]/,'');

            $("#diferencia_t").html("$"+result);




            var pa = parseInt($("#personal_a").text());
            var pb = parseInt($("#personal_b").text());
            var porc_a = (pb-pa)*100/pa;

            var porcx_a = "";

            if (porc_a>0) {
                porcx_a = '<span class="arraw up badge badge-danger"> %'+Math.round(porc_a)+' ↑</span>';
            }
            if (porc_a<0) {
                porcx_a = '<span class="arraw down badge badge-success"> %'+Math.round(porc_a)+' ↓</span>';
            }
            if (porc_a==0) {
                porcx_a = '<span class="arraw igual badge badge-light"> %'+Math.round(porc_a)+' ↔</span>';
            }

            $("#porc_per_a").html(porcx_a);



            var pca = parseInt($("#total_a").text().replace("$",""));
            var pcb = parseInt($("#total_b").text().replace("$",""));
            var porc_b = (pcb-pca)*100/pca;

            var porcx_b = "";

            if (porc_b>0) {
                porcx_b = '<span class="arraw up badge badge-danger"> %'+Math.round(porc_b)+' ↑</span>';
            }
            if (porc_b<0) {
                porcx_b = '<span class="arraw down badge badge-success"> %'+Math.round(porc_b)+' ↓</span>';
            }
            if (porc_b==0) {
                porcx_b = '<span class="arraw igual badge badge-light"> %'+Math.round(porc_b)+' ↔</span>';
            }

            $("#porc_cos_a").html(porcx_b);


            var dif_a = parseInt($("#total_a").text().replace("$",""));
           

            var difx_b = "";

            if (dif_a>0) {
                difx_b = '<span class="arraw up badge badge-danger"> ↑ </span>';
            }
            if (dif_a<0) {
                difx_b = '<span class="arraw down badge badge-success"> ↓ </span>';
            }
            if (dif_a==0) {
                difx_b = '<span class="arraw igual badge badge-light"> ↔ </span>';
            }

            $("#porc_dif").html(difx_b);





            const result_categoria = (id) => {
                var categoria = "";
                $.each(categorias.data, function(clave, value) {

                    if (value.codigo == id) {
                        categoria = value.nombre;
                    }
                })
                return categoria;
            }

           


            

            const result_remunerativo_a = (id) => {
                var remunerativo = "";
                $.each(costo_a.data, function(clave, value) {

                    if (clave == id) {
                        remunerativo = parseFloat(value.remunerativo.monto).toFixed(0);
                    }
                })
                return remunerativo;
            }

            const result_noremunerativo_a = (id) => {
                var noremunerativo = "";
                $.each(costo_a.data, function(clave, value) {

                    if (clave == id) {

                        if (value.no_remunerativo!=null) {

                        noremunerativo = parseFloat(value.no_remunerativo.monto).toFixed(0);

                        }
                    }
                })
                return noremunerativo;
            }

            const result_total_a = (id) => {
                var costo_laboral = "";
                $.each(costo_a.data, function(clave, value) {

                    if (clave == id) {

                        if (value.costo_laboral!=null) {

                        costo_laboral = parseFloat(value.costo_laboral.monto).toFixed(0);

                        }
                    }
                })
                return costo_laboral;
            }

            

            const result_remunerativo_b = (id) => {
                var remunerativo = "";
                $.each(costo_b.data, function(clave, value) {

                    if (clave == id) {
                        remunerativo = parseFloat(value.remunerativo.monto).toFixed(0);
                    }
                })
                return remunerativo;
            }

            const result_noremunerativo_b = (id) => {
                var noremunerativo = "";
                $.each(costo_b.data, function(clave, value) {

                    if (clave == id) {

                        if (value.no_remunerativo!=null) {

                        noremunerativo = parseFloat(value.no_remunerativo.monto).toFixed(0);

                        }
                    }
                })
                return noremunerativo;
            }

            const result_total_b = (id) => {
                var costo_laboral = "";
                $.each(costo_b.data, function(clave, value) {

                    if (clave == id) {

                        if (value.costo_laboral!=null) {

                        costo_laboral = value.costo_laboral.monto.toFixed(0);

                        }
                    }
                })
                return costo_laboral;
            }



              const basico_a = (id) => {
                var basico = "";
                $.each(costo_a.data, function(clave, value) {

                    if (clave == id) {
                        if (value['datos'].basico==null) {
                            basico=0;
                        }else{
                            basico = parseFloat(value['datos'].basico).toFixed(0);
                        }
                        
                    }
                })
                return basico;
            }

            const basico_b = (id) => {
                var basico = "";
                $.each(costo_b.data, function(clave, value) {

                   if (clave == id) {
                        if (value['datos'].basico==null) {
                            basico=0;
                        }else{
                            basico = parseFloat(value['datos'].basico).toFixed(0);
                        }
                        
                    }
                })
                return basico;
            }
            

             // abre liquidacion 1
            $.each(costo_a.data, function(clave, valor) {


                    
                   var p_total = (basico_b(clave)-basico_a(clave))*100/basico_a(clave);

                    if (p_total==Infinity) {
                         p_total = (basico_a(clave)-basico_b(clave))*100/basico_b(clave);
                    }


                
                    lista.push({
                      
                        "legajo": clave,
                        "nombre": valor.datos.nombre.split(', ')[1],
                        "apellido": valor.datos.nombre.split(', ')[0],
                        "ingreso": '',
                        "categoria": result_categoria(valor.datos.cat_tipo),
                        "remunerativo_a": moneda(result_remunerativo_a(clave)),
                        "noremunerativo_a": moneda(result_noremunerativo_a(clave)),
                        "costo_a": moneda(result_total_a(clave)),
                        "remunerativo_b": moneda(result_remunerativo_b(clave)),
                        "noremunerativo_b": moneda(result_noremunerativo_b(clave)),
                        "costo_b": moneda(result_total_b(clave)),
                        "porcentaje": Math.round(p_total),
                        "diferencia":moneda((basico_b(clave)-basico_a(clave)).toFixed(0)),
                        "basico_a":moneda(basico_a(clave)),
                        "basico_b":moneda(basico_b(clave))

                    })

                



            });

            // cierra liquidacion 1

              // abre liquidacion 1
            $.each(costo_b.data, function(clave, valor) {

              
                    var p_total = (basico_b(clave)-basico_a(clave))*100/basico_a(clave);

                    if (p_total==Infinity) {
                         p_total = (basico_a(clave)-basico_b(clave))*100/basico_b(clave);
                    }

                    
                    if (clave!=" ") {
                
                        lista.push({
                          
                             "legajo": clave,
                        "nombre": valor.datos.nombre.split(', ')[1],
                        "apellido": valor.datos.nombre.split(', ')[0],
                        "ingreso": '',
                        "categoria": result_categoria(valor.datos.cat_tipo),
                        "remunerativo_a": moneda(result_remunerativo_a(clave)),
                        "noremunerativo_a": moneda(result_noremunerativo_a(clave)),
                        "costo_a": moneda(result_total_a(clave)),
                        "remunerativo_b": moneda(result_remunerativo_b(clave)),
                        "noremunerativo_b": moneda(result_noremunerativo_b(clave)),
                        "costo_b": moneda(result_total_b(clave)),
                        "porcentaje": Math.round(p_total),
                        "diferencia":moneda((basico_b(clave)-basico_a(clave)).toFixed(0)),
                        "basico_a":moneda(basico_a(clave)),
                        "basico_b":moneda(basico_b(clave))

                        })

                    }



            });

            // cierra liquidacion 1

     
          
var unicos = this.removeDuplicates(lista, "legajo");


            this.personal = unicos;
            $("#loading").hide();

        })).then(() => {


            $('#empresas').DataTable({
                "scrollX": true,
                 "autoWidth": true,

                buttons: [
                    'copy',
                    'excel',
                    'csv',
                    'pdf'
                ]
            }).columns.adjust();



              

               

        });



        }

    },



};
</script>

<style scoped>
.load-box {
    background: red;
}


@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: 24px;
  -webkit-backface-visibility: hidden;
  left:0;
  right:0;
  top:0;
  bottom:0;
}

.up{
    color:green;
}
.down{
    color:red;
}

.colapse{
    position: absolute;
}
</style>
