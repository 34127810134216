<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">{{ breadcrumbB }}</li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ breadcrumbB }}</h6>
            <p class="card-description">{{ descripcion }}</p>
            <!-- Tabla de Categorías -->

        <div id="loading">
            <h6 class="card-title">Cargando datos! por favor espere unos segundos</h6>
        </div>

        <div id="datos">
             <h6 class="card-title">Personal,promedio de salario y antiguedad por Categorias</h6>
            <table id="categorias" class="table">
              <thead>
                <tr>
                  <th>Categoría</th>
                  <th>Cantidad de Personas</th>
                  <th>Promedio Salario</th>
                  <th>Promedio Antigüedad</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(categoria, index) in datosCategorias" :key="`categoria-${index}`">
                  <td>{{ categoria.nombreCategoria }}</td>
                  <td>{{ categoria.count }}</td>
                  <td>{{ categoria.promedioSalario.toFixed(2) }}</td>
                  <td>{{ categoria.promedioAntiguedad.toFixed(2) }}</td>
                </tr>
              </tbody>
            </table>
            <br><br>
           
            <h6 class="card-title">Personal,promedio de salario y antiguedad por Sectores</h6>
            <table id="sectores" class="table">
                <thead>
                    <tr>
                        <th>Sector</th>
                        <th>Cantidad de Personas</th>
                        <th>Promedio Antigüedad (Años)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(sector, index) in datosSectores" :key="`sector-${index}`">
                        <td>{{ sector.nombreSector }}</td>
                        <td>{{ sector.count }}</td>
                       <td>{{ sector.promedioAntiguedad.toFixed(2) }}</td>
                    </tr>
                </tbody>
            </table>

        </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();

export default {
  name: 'Dashboard',
  data: () => ({
    breadcrumbA: 'Inicio',
    breadcrumbB: 'Dashboard',
    descripcion: 'Usted esta en el Dashboard',
    datosCategorias: [],
    datosSectores: [],
  }),
  async mounted() {
    const hoy = new Date();
    const primerDiaMesAnterior = new Date(hoy.getFullYear(), hoy.getMonth() - 1, 1);
    const ultimoDiaMesAnterior = new Date(hoy.getFullYear(), hoy.getMonth(), 0);
    const formatFecha = fecha => fecha.toISOString().split('T')[0];

    const axiosrequest4 = axios.get(`https://consumos.foodservice.com.ar/api/totalEmpresas/${formatFecha(primerDiaMesAnterior)}/${formatFecha(ultimoDiaMesAnterior)}`);
    const axiosPersonal = axios.get('https://auth.foodservice.com.ar/?type=personal&access_token=1234567');
    const axiosCategorias = axios.get('https://aplicativos.firebaseio.com/categorias.json');
    const axiosSectores = axios.get('https://aplicativos.firebaseio.com/sectores.json');
    const axiosDistribucion = axios.get('https://aplicativos.firebaseio.com/distribucion.json?print=pretty');

    try {
      const [responseEmpresas, responsePersonal, responseCategorias, responseSectores, responseDistribucion] = await Promise.all([axiosrequest4, axiosPersonal, axiosCategorias, axiosSectores, axiosDistribucion]);

      const personalData = responsePersonal.data.filter(persona => !persona.leg_fecegr);
      const categoriasData = responseCategorias.data;
      // Nuevos datos de sectores
    const sectoresData = responseSectores.data;
    const distribucionData = responseDistribucion.data;




      let categorias = {};

      personalData.forEach(persona => {
        if (!categorias[persona.cat_codigo]) {
          categorias[persona.cat_codigo] = {
            count: 0,
            sumSalario: 0,
            sumAntiguedad: 0,
            nombreCategoria: categoriasData[persona.cat_codigo] ? categoriasData[persona.cat_codigo].nombre : 'Desconocido',
          };
        }
        categorias[persona.cat_codigo].count++;
        categorias[persona.cat_codigo].sumSalario += parseFloat(persona.leg_basico || 0);
        const fechaIngreso = new Date(persona.leg_fecingr.date);
        const antiguedad = hoy.getFullYear() - fechaIngreso.getFullYear();
        categorias[persona.cat_codigo].sumAntiguedad += antiguedad;
      });

      Object.keys(categorias).forEach(key => {
        categorias[key].promedioSalario = categorias[key].sumSalario / categorias[key].count;
        categorias[key].promedioAntiguedad = categorias[key].sumAntiguedad / categorias[key].count;
      });

      


  // Procesamiento de sectores
const sectores = {};
Object.keys(distribucionData).forEach(idEmpresa => {
    const empleadosSector = distribucionData[idEmpresa];
    // Encuentra el sector correspondiente usando 'id_empresa'
    const sectorInfo = sectoresData.find(sector => sector.id_empresa === idEmpresa);

    // Si no se encuentra el sector correspondiente, se omite este ciclo del bucle
    if (!sectorInfo) return;

    const nombreSector = sectorInfo.id_empresa;

   console.log(nombreSector)
   console.log(sectores)
   

    if (!sectores[nombreSector]) {
        sectores[nombreSector] = { count: 0, sumAntiguedad: 0, nombreSector: sectorInfo.operacion };
    }

     

 
   Object.values(empleadosSector).forEach(empleado => {

        // Aquí se asume que cada 'empleado' corresponde a una persona en 'personalData'
        const empleadoInfo = personalData.find(persona => parseInt(persona.leg_numero) === parseInt(empleado.legajo));
        
        if (!empleadoInfo) return;

        const fechaIngreso = new Date(empleadoInfo.leg_fecingr.date);
        const antiguedad = hoy.getFullYear() - fechaIngreso.getFullYear();
        sectores[nombreSector].count++;
        sectores[nombreSector].sumAntiguedad += antiguedad;
        
        
    });

   sectores[nombreSector].promedioAntiguedad = sectores[nombreSector].sumAntiguedad / sectores[nombreSector].count;
});

// Asegúrate de que la propiedad 'datosSectores' se actualice correctamente
this.datosSectores = Object.values(sectores);


    // Actualizar el estado del componente con los datos de categorías y sectores procesados
    this.datosCategorias = Object.values(categorias); // Esto ya se hacía
   


      // Procesamiento de sectores similar al de categorías...

      this.$nextTick(() => {
        $('#categorias').DataTable({
          "search": { regex:true },
          dom: 'Bfrtip',
          buttons: ['copy', 'excel', 'csv', 'pdf']
        });

         $('#sectores').DataTable({
          "search": { regex:true },
          dom: 'Bfrtip',
          buttons: ['copy', 'excel', 'csv', 'pdf']
        });

         $("#datos").show()
         $("#loading").hide()

        // Aquí podrías inicializar una DataTable adicional para los sectores si es necesario
      });
    } catch (error) {
      console.error('Error al realizar las solicitudes:', error);
    }
  }
};
</script>

<style scoped>
#loading{display: block;}
#datos{display: none;}
</style>
