import { createWebHistory, createRouter } from "vue-router";


import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";


import Empresas from "@/views/empresas/Empresas.vue";
import Sectores from "@/views/sectores/Sectores.vue";
import CostosPersonal from "@/views/costos/CostosPersonal.vue";
import CostosPersonalCat from "@/views/costos/CostosPersonalCat.vue";
import CostosPersonalP from "@/views/costos/CostosPersonalPorcent.vue";
import CostosPersonalCatP from "@/views/costos/CostosPersonalCatPorcent.vue";
import CostosPersonalGroup from "@/views/costos/CostosPersonalGroup.vue";
import CostosPersonalPGroup from "@/views/costos/CostosPersonalPorcentGroup.vue";
import CostosGuardados from "@/views/costos/CostosGuardados.vue";
import BloquesGuardados from "@/views/costos/BloquesGuardados.vue";
import Salida from "@/views/cantidades/Salida.vue";
import Plato from "@/views/cantidades/Plato.vue";
import Cocina from "@/views/cantidades/Cocina.vue";
import ProGuardados from "@/views/costos/ProGuardados.vue";
import DownGuardados from "@/views/costos/DownGuardados.vue";
import ViewGuardados from "@/views/costos/ViewGuardados.vue";
import ViewConvenio from "@/views/costos/ViewConvenio.vue";
import RequiSalarial from "@/views/costos/RequiSalarial.vue";
import EvolucionSalarial from "@/views/costos/EvolucionSalarial.vue";

const routes = [

 {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
   {
    path: "/Login",
    name: "Login",
    component: Login,
  },
  {
    path: "/empresas/Empresas",
    name: "Empresas",
    component: Empresas,
  },{
    path: "/sectores/Sectores",
    name: "Sectores",
    component: Sectores,
  },
  {
    path: "/costos/CostosPersonal",
    name: "CostosPersonal",
    component: CostosPersonal,
  },
  {
    path: "/costos/CostosPersonalCat",
    name: "CostosPersonalCat",
    component: CostosPersonalCat,
  },


{
    path: "/costos/CostosPersonalP",
    name: "CostosPersonalP",
    component: CostosPersonalP,
  },
  {
    path: "/costos/CostosPersonalCatP",
    name: "CostosPersonalCatP",
    component: CostosPersonalCatP,
  },

  {
    path: "/costos/CostosPersonalGroup",
    name: "CostosPersonalGroup", 
    component: CostosPersonalGroup,
  },

  {
    path: "/costos/RequiSalarial",
    name: "RequiSalarial", 
    component: RequiSalarial,
  },

  {
    path: "/costos/CostosGuardados",
    name: "CostosGuardados", 
    component: CostosGuardados,
  },

  {
    path: "/costos/ProGuardados",
    name: "ProGuardados", 
    component: ProGuardados,
  },

  {
    path: "/costos/ProGuardados",
    name: "ProGuardados", 
    component: ProGuardados,
  },

  {
    path: "/costos/EvolucionSalarial",
    name: "EvolucionSalarial", 
    component: EvolucionSalarial,
  },

  {
    path: "/costos/DownGuardados",
    name: "DownGuardados", 
    component: DownGuardados,
  },

   {
    path: "/costos/ViewGuardados",
    name: "ViewGuardados", 
    component: ViewGuardados,
  },

   {
    path: "/costos/ViewConvenio",
    name: "ViewConvenio", 
    component: ViewConvenio,
  },

  {
    path: "/costos/BloquesGuardados",
    name: "BloquesGuardados", 
    component: BloquesGuardados,
  },



  {
    path: "/cantidades/Salida",
    name: "Salida",
    component: Salida,
  },
  {
    path: "/cantidades/Plato",
    name: "Plato",
    component: Plato,
  },
   {
    path: "/cantidades/Cocina",
    name: "Cocina",
    component: Cocina,
  },

 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'hash'
});

export default router;